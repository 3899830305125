import React from 'react';
import PropTypes from 'prop-types';
import useCurrentUser from './useCurrentUser';
import Spinner from '../components/common/Spinner';

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const [user, , error, loading] = useCurrentUser();
  if (loading) {
    return <Spinner position="absolute" />;
  }
  if (error) {
    const unautorizedException = {
      innerException: error,
      type: 'isUnauthorized',
      name: 'Unauthorized user',
      message: error.toString(),
    };
    throw unautorizedException;
  }
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default UserContextProvider;
