import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MENU as menu } from '../common/constants';
import RestrictedContent from './common/RestrictedContent';

const Sidebar = ({
  activatedCurrentIcon,
  setActivatedCurrentIcon,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [currentIcon, setCurrentIcon] = useState(null);
  const handleMouseEnter = (menuItem) => {
    setCurrentIcon(menuItem);
  };

  const handleMouseLeave = () => {
    setCurrentIcon(null);
  };

  const handleClick = (menuItem) => {
    setActivatedCurrentIcon(menuItem.linkTo);
  };

  const location = useLocation();
  const isAuditSessionRoute = location.pathname.includes('audit-session');

  return (
    <div className="flex w-24">
      <nav className="bg-white shadow flex w-min fixed h-full z-10">
        <div
          className={`flex flex-col w-24 py-12 items-center flex-grow ${
            isAuditSessionRoute && 'pt-40'
          }`}
        >
          {menu.map((menuItem, index) => {
            const { description, linkTo, icon } = menuItem;
            return (
              <RestrictedContent key={index} roles={menuItem.roles}>
                <div
                  className={`text-tuvr-gray p-6 w-full ${
                    activatedCurrentIcon === linkTo && 'bg-tuvr-new-bg'
                  }`}
                >
                  <div className="relative flex items-center justify-center">
                    {currentIcon?.linkTo === linkTo && (
                      <div
                        className="absolute text-sm whitespace-no-wrap bg-tuvr-dark-blue text-white px-4 py-2 rounded flex items-center transition-all duration-150"
                        style={{ left: '50%', opacity: 1, marginLeft: '20px' }}
                      >
                        <div
                          className="bg-tuvr-dark-blue h-3 w-3 absolute"
                          style={{
                            left: '-3px',
                            transform: 'rotate(45deg)',
                          }}
                        />
                        {t(description)}
                      </div>
                    )}
                    <Link
                      to={isDisabled ? '#' : linkTo}
                      onMouseEnter={() => handleMouseEnter(menuItem)}
                      onMouseLeave={() => handleMouseLeave()}
                      onClick={() => handleClick(menuItem)}
                      className={classNames(
                        isDisabled ? 'text-gray-300 cursor-not-allowed' : ''
                      )}
                    >
                      <div style={{ fontSize: '2.3rem' }}>
                        <FontAwesomeIcon
                          icon={icon}
                          size="1x"
                          className={`hover:text-tuvr-dark-blue ${
                            activatedCurrentIcon === linkTo &&
                            'text-tuvr-dark-blue'
                          }`}
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </RestrictedContent>
            );
          })}
        </div>
      </nav>
    </div>
  );
};

Sidebar.propTypes = {
  activatedCurrentIcon: PropTypes.string,
  setActivatedCurrentIcon: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default Sidebar;
