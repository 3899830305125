import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white flex-1 items-center justify-center mt-28">
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-tuvr-blue tracking-wide uppercase">
            {t('MESSAGES.NOT_FOUND.HEADER')}
          </h2>
          <p className="mt-1 text-4xl font-extrabold text-tuvr-gray sm:text-5xl sm:tracking-tight lg:text-6xl">
            {t('MESSAGES.NOT_FOUND.TITLE')}
          </p>
          <p className="mt-5 mx-auto text-xl text-gray-500">
            {t('MESSAGES.NOT_FOUND.SUBTITLE')}{' '}
            <Link to="/">
              <button type="button" className="text-tuvr-blue font-bold">
                Home
              </button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
