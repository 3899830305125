export const getTokenForced = async (provider, config, account) => {
  try {
    const token = await provider.acquireTokenSilent({
      scopes: config.idp.scopes,
      account,
      forceRefresh: true,
    });
    return token;
  } catch (err) {
    if (err.errorMessage && err.errorMessage.includes('AADB2C90077')) {
      return provider.acquireTokenRedirect({
        scopes: config.idp.scopes,
        account,
        state: window.location.pathname,
      });
    }
    return null;
  }
};
const cache = {};
// retrigger

export const getToken = async (provider, config, account) => {
  try {
    if (
      cache?.token &&
      new Date().getTime() < cache.token?.expiresOn?.getTime()
    ) {
      return cache.token;
    }
    const token = await provider.acquireTokenSilent({
      scopes: config.idp.scopes,
      account,
    });
    cache.token = token;
    return cache.token;
  } catch (err) {
    const token = await getTokenForced(provider, config, account);
    return token;
  }
};
