import {
  faLandmark,
  faTachometerAlt,
  faTasks,
  faUsers,
  faInfoCircle,
  faGlobe,
} from '@fortawesome/pro-light-svg-icons';

export const APP_VERSION = 'v2.1.2';

export const APP_LANGUAGES = {
  en: {
    code: 'en',
    translation: 'PROFILE.L_ENGLISH',
  },
  es: {
    code: 'es',
    translation: 'PROFILE.L_SPANISH',
  },
  de: {
    code: 'de',
    translation: 'PROFILE.L_GERMAN',
  },
  hu: {
    code: 'hu',
    translation: 'PROFILE.L_HUNGARIAN',
  },
  ja: {
    code: 'ja',
    translation: 'PROFILE.L_JAPAN',
  },
  zh: {
    code: 'zh',
    translation: 'PROFILE.L_CHINESE',
  },
};

export const TRANSLATION_CATEGORY = {
  AUDIT_TYPE: 1,
  WELCOME_PAGE_CATEGORY: 2,
  PROFILE: 3,
};
export const AUDIT_STATUS = {
  IN_DRAFT: 'STATUS_IN_DRAFT',
  LIVE: 'STATUS_LIVE',
  PREPARED: 'STATUS_PREPARED',
  COMPLETED: 'STATUS_COMPLETED',
  DELETED: 'STATUS_DELETED',
  INTERRUPTED: 'STATUS_INTERRUPTED',
  CLOSED: 'STATUS_CLOSED',
};

export const MEDIA_KIND = {
  AUDIO_INPUT: 'audioinput',
  AUDIO_OUTPUT: 'audiooutput',
  VIDEO_INPUT: 'videoinput',
};

export const AUDIT_ROLE = {
  EXPERT: 'ROLE_EXPERT',
  INTERPRETER: 'ROLE_INTERPRETER',
  PARTICIPANT: 'ROLE_PARTICIPANT',
  AUDITOR: 'ROLE_AUDITOR',
  SUPPORT: 'ROLE_SUPPORT',
  TEAM_LEADER: 'ROLE_TEAM_LEADER',
  TEAM_LEADER_DEPUTY: 'ROLE_TEAM_LEADER_DEPUTY',
  WITNESS: 'ROLE_WITNESS',
  TRAINEE: 'ROLE_TRAINEE',
  MAIN_CONTACT_PERSON: 'ROLE_MAIN_CONTACT_PERSON',
};

export const AUDIT_ROLES_FILE_OPTIONS = {
  EXPERT: 'ROLE_EXPERT',
  INTERPRETER: 'ROLE_INTERPRETER',
  PARTICIPANT: 'ROLE_PARTICIPANT',
  AUDITOR: 'ROLE_AUDITOR',
  WITNESS: 'ROLE_WITNESS',
  TRAINEE: 'ROLE_TRAINEE',
  MAIN_CONTACT_PERSON: 'ROLE_MAIN_CONTACT_PERSON',
};

export const FILE_DEFAULT_PERMISSIONS = [
  'ROLE:SUPPORT',
  'ROLE:TEAM_LEADER',
  'ROLE:TEAM_LEADER_DEPUTY',
];

export const AUDIT_SOURCE = {
  TUV: 'TUV',
  CUSTOMER: 'CUSTOMER',
  GUEST: 'GUEST',
  AUDIT_TEAM: 'AUDIT TEAM',
  OTHER: 'OTHER',
};

export const AUDIT_FIELD_FORMAT_VALUES = {
  AUDIT_START_DAY_FIELDNAME: 'auditStartTime',
  AUDIT_END_DAY_FIELDNAME: 'auditEndTime',
  DATE_FORMAT_DE: 'dd.MM.yyyy',
  DATE_FORMAT_EN: 'MM/dd/yyyy',
  DATE_FORMAT_DEFAULT: 'yyyy-MM-dd',
};

export const CHAT = {
  CONNECTION_OPENED: 'CONNECTION_OPENED',
  CONNECTION_CLOSED: 'CONNECTION_CLOSED',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  OPEN_ON_PRIVATE_CHAT: 'OPEN_ON_PRIVATE_CHAT',
  OPEN_ON_RECEIVE_GROUP: 'OPEN_ON_RECEIVE_GROUP',
  ON_MESSAGE_RECEIVED: 'ON_MESSAGE_RECEIVED',
  NOTIFY_ON_MESSAGE_RECEIVED: 'NOTIFY_ON_MESSAGE_RECEIVED',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
  RESUME_NOTIFICATIONS: 'RESUME_NOTIFICATIONS',
  ON_CHAT_OPENED: 'ON_CHAT_OPENED',
  WAMP_SESSION_SERVER: 'com.wamp.session.server',
  WAMP_SESSION_PUBLIC: 'com.wamp.session.public',
  WAMP_SESSION_PRIVATE: 'com.wamp.session.private',
  ADD_ATTENDEE: 'addAttendee',
  REMOVE_ATTENDEES: 'removeAttendees',
  RECONNECT: 'reconnect',
  NEW_CHAT: 'newChat',
  ADD_ME: 'addMe',
  REMOVE_ME: 'removeMe',
  CHANGE_ATTENDEES: 'changedAttendees',
  ADD_MESSAGE: 'addMessage',
  REMOVE_MESSAGE: 'removeMessage',
  CHANGED_MESSAGE: 'changedMessage',
  PUBLIC_CHAT: 'Everybody',
  PRIVATE_CHAT_GROUP: 'PRIVATE_CHAT_GROUP',
  PRIVATE_CHAT_DROPDOWN: 'PRIVATE_CHAT_DROPDOWN',
  HAND_RAISED_NOTIFICATIONS: 'HAND_RAISED_NOTIFICATIONS',
};

export const BACKGROUND_IMAGES_ROW_ID = {
  NONE: 0,
  BLUR: 1,
};

export const BACKGROUND_IMAGES_STATUS = {
  NONE: undefined,
  BLUR: 'blur',
};

export const AUDIT_SESSION = {
  WAMP_SESSION_AUDIT: 'com.wamp.session.audit',
  WAMP_HANDRAISED_AUDIT: 'com.wamp.raised.audit',
  WAMP_SESSION_ONLINE_STATUS: 'com.wamp.session.status_online',
  REFETCH_TYPES: {
    audit_started: 1,
    audit_completed: 2,
    meeting_started: 3,
    meeting_updated: 4,
    meeting_ended: 5,
    meeting_added: 6,
    participant_added: 6,
    document_added: 7,
    document_updated: 8,
    document_deleted: 9,
    participant_added_by_buno: 10,
    participant_added_by_buno_local: 11,
    DEFAULT_MEETING_REQUEST: 12,
    DEFAULT_MEETING_RESPONSE: 13,
    audit_interupted: 14,
    participant_role_changed: 15,
    audit_tl_changed: 16,
  },
  ONLINE_STATUS: {
    IS_MOBILE_ON_JOIN: 'IS_MOBILE_ON_JOIN',
    IS_MOBILE_REQUEST: 'IS_MOBILE_REQUEST',
    IS_MOBILE_ON_LEAVE: 'IS_MOBILE_ON_LEAVE',
    IS_MOBILE_ON_END: 'IS_MOBILE_ON_END',
    KICK_OFF_PARTICIPANT: 'KICK_OFF_PARTICIPANT',
  },
};

export const SUPPORTED_MIME_TYPES = {
  VIDEO: 'video/mp4',
  POWERPOINT:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  VTT: 'text/vtt',
};

export const DOCUMENTS_API_CODES = {
  FILE_NOT_ALLOWED: 1, // means file is not whitelisted or maximum of allowed file size is reached.
};

export const SYSTEM_ROLE = {
  ROOT: 'ROOT',
  GUEST: 'GUEST',
  AUDITOR: 'AUDITOR',
  AUDIT_SUPPORT: 'AUDIT_SUPPORT',
};

export const SYSTEM_ACCOUNT_TYPE = {
  ADMIN: 'ADMIN',
};

export const SYSTEM_ACCOUNT_TASKS = {
  LANGUAGE_TASK: 'LANGUAGE',
  BACKGROUND_TASK: 'BACKGROUND',
};

export const SYSTEM_HTML_TAG = {
  DIVIDER: 'DIVIDER',
};

export const MEETING_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  ENDED: 'ENDED',
};

export const MENU = [
  {
    description: 'MENU.DASHBOARD',
    linkTo: '/',
    icon: faTachometerAlt,
    isHome: true,
    roles: null,
  },
  {
    description: 'MENU.AUDITS',
    linkTo: '/audits',
    icon: faTasks,
    isHome: false,
    roles: [SYSTEM_ROLE.AUDIT_SUPPORT, SYSTEM_ROLE.ROOT],
  },
  {
    description: 'MENU.TRAINING',
    linkTo: '/training-center',
    icon: faLandmark,
    isHome: false,
    roles: null,
  },
  {
    description: 'MENU.USER_ADMINISTRATION',
    linkTo: '/admin',
    icon: faUsers,
    isHome: false,
    roles: [SYSTEM_ROLE.AUDIT_SUPPORT, SYSTEM_ROLE.ROOT],
  },
  {
    description: 'MENU.TRANSLATION_ADMINISTRATION',
    linkTo: '/translation',
    icon: faGlobe,
    isHome: false,
    roles: [SYSTEM_ROLE.AUDIT_SUPPORT, SYSTEM_ROLE.ROOT],
  },
  {
    description: 'MENU.ABOUT',
    linkTo: '/about',
    icon: faInfoCircle,
    isHome: false,
    roles: null,
  },
];

export const HOURS_FORMAT = {
  TWENTY_FOUR: 'TWENTY_FOUR',
  TWELVE: 'TWELVE',
};

export const NAVBAR_ITEMS = {
  FILES: 'files',
  CHAT: 'chat',
  PARTICIPANTS: 'participants',
  TOOLS: 'tools',
  ZOOM_PARTICIPANTS: 'zoom_participants',
  HANDRAISED: 'handRaised',
  BACKGROUND_IMAGES: 'background_images',
  AGENDA: 'agenda',
};

export const NAVBAR_PARTICIPANTS_ITEMS = {
  PARTICIPANTS: 0,
  ZOOM_PARTICIPANTS: 1,
};

export const GROUP_BY_VALUE = 6;

export const ERROR = {
  IS_NOT_FOUND: 'isNotFound',
  IS_UNAUTHORIZED: 'isUnauthorized',
  IS_INTERNAL_ERROR: 'isInternalError',
  IS_ZOOM_ERROR: 'isZoomError',
};

export const LOCAL_STORAGE = {
  ZOOM_MEETING: 'ZOOM_MEETING',
};

export const LOCAL = {
  EN: 'en',
  DE: 'de',
  ES: 'es',
  JP: 'ja',
  HU: 'hu',
};

export const BUCKET = {
  TUTORIALS: 'tutorials',
};

export const AUDIT_TYPES = [
  {
    value: 'Certification audit  ',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.CERT',
  },
  {
    value: 'First Surveillance Audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.FIRST_SURVEILLANCE',
  },
  {
    value: 'Surveillance Audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.SURVEILLANCE',
  },
  {
    value: 'Re-certification Audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.RE_CERT',
  },
  {
    value: 'Internal audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.INTERNAL',
  },
  {
    value: 'PreAudit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.PRE',
  },
  {
    value: 'Project',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.PROJECT',
  },
  {
    value: 'Corporate scheme Audit ',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.CORPORATE_SCHEME',
  },
  {
    value: 'Stage 1',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.STAGE_1',
  },
  {
    value: 'Stage 2',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.STAGE_2',
  },
  {
    value: 'Extension Audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.EXTENSION',
  },
  {
    value: 'Stages Certification Audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.STAGES_CERT',
  },
  {
    value: 'Special Audit ',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.SPECIAL',
  },
  {
    value: 'Stages Re-certification Audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.STAGES_RE_CERT',
  },
  {
    value: 'Transfer Audit',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.TRANSFER',
  },
  {
    value: 'Letter of conformance',
    label: 'AUDIT.WIZARD.STEPS.DETAILS.AUDIT_TYPE_OPTIONS.LETTER_CONFORMANCE',
  },
];
