/* This example requires Tailwind CSS v2.0+ */
import { useMsal } from '@azure/msal-react';

const useSignOut = () => {
  const { instance } = useMsal();

  const signOut = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  return signOut;
};

export default useSignOut;
