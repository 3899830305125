import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context';
import { getToken } from './common/auth-utils';

export const getApolloClient = (config, authProvider, account) => {
  const httpLink = createHttpLink({
    uri: config.gqlEndpoint,
  });

  const authLink = setContext(async (_, anotherOne) => {
    // get the authentication token from local storage if it exists
    const token = await getToken(authProvider, config, account);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...anotherOne.headers,
        ...(token &&
          token.accessToken && {
            Authorization: `Bearer ${token.accessToken}`,
          }),
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};
