import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';

const MailToButton = ({ mailto, label }) => (
  <a href={mailto}>
    <FontAwesomeIcon icon={faEnvelope} />
    {label}
  </a>
);
MailToButton.propTypes = {
  mailto: PropTypes.string,
  label: PropTypes.string,
};

export default MailToButton;
