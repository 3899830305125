import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const Spinner = ({
  title,
  subTitle,
  just,
  size,
  position,
  color,
  textColor,
  otherstyles,
}) => {
  const { t } = useTranslation();

  const [spinnerTitle, spinnerMessage] = [
    title || t('GENERIC.SPINNER_TITLE'),
    subTitle || t('GENERIC.SPINNER_SUBTITLE'),
  ];
  const spinnerSize = size || '4x';
  const spinnerPosition = position || 'relative';
  const spinnerTextColor = textColor || 'text-tuvr-gray';
  const spinnerColor = color || 'text-tuvr-primary-blue';
  //   const spinnerOverlay = fullScreen ? 'w-screen h-screen' : 'w-full h-full';
  // todo: spinner with more flexibility
  return (
    <div
      className={classNames(
        'top-0 left-0 right-0 bottom-0 z-10 overflow-hidden bg-gray-200 bg-opacity-75 flex flex-col items-center justify-center',
        spinnerPosition
      )}
      style={otherstyles}
    >
      <FontAwesomeIcon
        className={classNames('animate-spin', spinnerColor)}
        size={spinnerSize}
        icon={faSpinnerThird}
      />
      {!just ? (
        <div className={classNames('text-center mt-5', spinnerTextColor)}>
          <p className="text-2xl font-semibold">{spinnerTitle}</p>
          <p className="text-xl">{spinnerMessage}</p>
        </div>
      ) : null}
    </div>
  );
};

Spinner.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  just: PropTypes.bool,
  size: PropTypes.string,
  position: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  otherstyles: PropTypes.object,
};
export default Spinner;
