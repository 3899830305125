import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle,
  faClipboardCheck,
  faExclamationCircle,
  faHandPaper,
  faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ShowLongTextContext } from '../../context/ShowLongTextContext';
import { NotificationContext } from '../../context/NotificationsContext';

const SnackbarLongText = () => {
  const { t } = useTranslation();
  const { notification, dismiss } = useContext(ShowLongTextContext);
  const [show, setShow] = useState(false);
  const timeoutId = useRef();
  const { notify } = useContext(NotificationContext);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setShow(true);
    if (!notification?.stick) {
      timeoutId.current = setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [notification]);

  if (!notification) {
    return null;
  }

  const handleDismiss = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setShow(false);
    dismiss();
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(notification.text);
    setShow(false);
    notify(t('TRANSLATION_ADMINISTRATION.CLIPBOARD'), 'success');
  };

  const getColor = () => {
    switch (notification.type) {
      case 'success':
        return 'text-tuvr-green';
      case 'error':
        return 'text-tuvr-red';
      case 'warning':
        return 'text-tuvr-yellow';
      case 'hand-raised':
        return 'text-tuvr-yellow';
      default:
        return 'text-tuvr-primary-blue';
    }
  };

  const getIcon = () => {
    switch (notification.type) {
      case 'hand-raised':
        return faHandPaper;
      case 'success':
        return faCheckCircle;
      case 'error':
        return faTimesCircle;
      case 'warning':
        return faExclamationCircle;
      default:
        return faInfoCircle;
    }
  };

  return (
    <div className="fixed w-full flex top-36 items-center ml-64 justify-start">
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-lg w-full bg-tuvr-gray  shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex flex-row gap-4 items-center">
              <div className="flex-shrink-0">
                <div className={getColor()}>
                  <FontAwesomeIcon
                    size="2x"
                    icon={getIcon()}
                    aria-hidden="true"
                  />
                </div>
              </div>
              <p className="text-sm flex-1 text-white">{notification.text}</p>

              <button
                onClick={handleDismiss}
                type="button"
                className="rounded inline-flex text-gray-400 hover:text-gray-100 focus:outline-none"
              >
                <span className="sr-only">Dismiss</span>
                <div className="text-sm">X</div>
              </button>
            </div>
          </div>
          <button
            onClick={copyToClipBoard}
            type="button"
            className="rounded p-5 inline-flex text-gray-400 hover:text-gray-100 focus:outline-none float-right"
          >
            <FontAwesomeIcon
              icon={faClipboardCheck}
              size="2x"
              className="rounded inline-flex text-gray-400 hover:text-gray-100 focus:outline-none"
            />
          </button>
        </div>
      </Transition>
    </div>
  );
};

export default SnackbarLongText;
