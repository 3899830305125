import gql from 'graphql-tag';

export const AUDIT_SESSION_SUBSCRIPTION = gql`
  subscription AUDIT_SESSION_SUBSCRIPTION($auditSessionId: String!) {
    ras_audits(where: { session_id: { _eq: $auditSessionId } }) {
      id
      session_id
      start_date
      welcome_message_title
      welcome_message_subtitle
      welcome_page_settings
      timezone
      dates
      company {
        id
        name
      }
      sales_office {
        id
        name
      }
      end_date
      customer_name
      customer_central_address
      customer_number
      locations
      certificates(order_by: { order: asc }) {
        id
        number
        standard
        type
        audit_identifier
      }
      team_leader {
        id
      }
      support_employee {
        id
      }
      status
      audit_plan
      ict_meeting
    }
  }
`;

export const AUDIT_SESSION_MOBILE_SUBSCRIPTION = gql`
  subscription AUDIT_SESSION_SUBSCRIPTION($auditSessionId: String!) {
    ras_audits(where: { session_id: { _eq: $auditSessionId } }) {
      id
      session_id
      start_date
      welcome_message_title
      welcome_message_subtitle
      welcome_page_settings
      timezone
      dates
      company {
        id
        name
      }
      sales_office {
        id
        name
      }
      end_date
      customer_name
      customer_central_address
      customer_number
      ict_meeting
      locations
      certificates(order_by: { order: asc }) {
        id
        number
        standard
        type
        audit_identifier
      }
      team_leader {
        id
      }
      support_employee {
        id
      }
      status
      audit_plan
      ict_meeting
    }
  }
`;

export const AUDIT_SESSION_MEETINGS_SUBSCRIPTION = gql`
  subscription AUDIT_SESSION_MEETINGS_SUBSCRIPTION($auditSessionId: String!) {
    ras_audit_meetings(
      order_by: [{ date: asc }, { start_time: asc }]
      where: { audit: { session_id: { _eq: $auditSessionId } } }
    ) {
      id
      title
      description
      date
      start_time
      end_time
      meeting_id
      meeting_access_code
      status
      started_at
      ended_at
      lane_id
      teams_meeting
      teams_as_default_conference
      participants {
        id
        user_id
        meeting_id
        participant {
          id
          name
          email
          hr_number
          initials
          profile_picture
          title
          source_directory
          nationality
        }
      }
    }
  }
`;

export const AUDIT_SESSION_PARTICIPANTS_SUBSCRIPTION = gql`
  subscription AUDIT_SESSION_PARTICIPANTS_SUBSCRIPTION(
    $auditSessionId: String!
  ) {
    ras_audit_participants(
      where: { audit: { session_id: { _eq: $auditSessionId } } }
    ) {
      id
      user_id
      roles
      user {
        id
        name
        email
        source
        phone_number
        hr_number
        initials
        profile_picture
        qualifications
        title
        source_directory
        nationality
      }
    }
  }
`;

export const AUDIT_SESSION_DOCUMENTS_SUBSCRIPTION = gql`
  subscription AUDIT_SESSION_DOCUMENTS_SUBSCRIPTION($auditSessionId: String!) {
    ras_documents(where: { audit: { session_id: { _eq: $auditSessionId } } }) {
      id
      confidentiality
      audit_id
      author {
        id
        name
      }
      editor {
        id
        name
      }
      created_at
      updated_at
      dms_path
      file_name
      file_type
      mime_type
      lifespan
      permissions
    }
  }
`;

export const INSERT_AUDIT_REPORT_DATA = gql`
  mutation INSERT_AUDIT_REPORT_DATA($auditId: Int!) {
    insert_audit_report_data(auditId: $auditId) {
      success
      error
    }
  }
`;

export const GET_SESSION_SIGNATURE_QUERY = gql`
  query GET_SESSION_SIGNATURE_QUERY(
    $topic: String!
    $password: String!
    $role: Int!
  ) {
    get_session_signature(topic: $topic, password: $password, role: $role)
  }
`;

export const GET_AUDIT_SESSION_DOCUMENTS = gql`
  query GET_AUDIT_DOCUMENTS($sessionId: String!) {
    ras_documents(
      order_by: { created_at: desc }
      where: { audit: { session_id: { _eq: $sessionId } } }
    ) {
      id
      confidentiality
      audit_id
      author {
        id
        name
      }
      editor {
        id
        name
      }
      created_at
      updated_at
      dms_path
      file_name
      file_type
      mime_type
      lifespan
      permissions
    }
  }
`;

export const GET_AUDIT_BY_SESSION_ID_QUERY = gql`
  query GET_AUDIT_BY_SESSION_ID_QUERY($sessionId: String!) {
    ras_audits(where: { session_id: { _eq: $sessionId } }, limit: 1) {
      id
      internal_acdc_url
      external_acdc_url
      acdc_id
      session_id
      start_date
      welcome_message_title
      welcome_message_subtitle
      welcome_page_settings
      timezone
      dates
      company {
        id
        name
      }
      sales_office {
        id
        name
      }
      end_date
      customer_name
      customer_central_address
      customer_number
      locations
      ict_meeting
      certificates(order_by: { order: asc }) {
        id
        number
        standard
        type
        audit_identifier
      }
      team_leader {
        id
      }
      support_employee {
        id
      }
      status
      audit_plan
      documents {
        id
        confidentiality
        audit_id
        author {
          id
          name
        }
        editor {
          id
          name
        }
        created_at
        updated_at
        dms_path
        file_name
        file_type
        mime_type
        lifespan
        permissions
      }
      participants {
        id
        user_id
        roles
        user {
          id
          name
          email
          source
          hr_number
          phone_number
          initials
          profile_picture
          qualifications
          title
          source_directory
          nationality
        }
      }
      meetings(order_by: [{ date: asc }, { start_time: asc }]) {
        id
        title
        description
        date
        start_time
        end_time
        meeting_id
        meeting_access_code
        status
        started_at
        ended_at
        lane_id
        teams_meeting
        teams_as_default_conference
        participants {
          id
          user_id
          meeting_id
          participant {
            id
            name
            email
            hr_number
            initials
            profile_picture
            title
            source_directory
            nationality
          }
        }
      }
    }
  }
`;

export const GET_AUDIT_BY_SESSION_ID_MOBILE_QUERY = gql`
  query GET_AUDIT_BY_SESSION_ID_MOBILE_QUERY($sessionId: String!) {
    ras_audits(where: { session_id: { _eq: $sessionId } }, limit: 1) {
      id
      internal_acdc_url
      external_acdc_url
      acdc_id
      session_id
      start_date
      welcome_message_title
      welcome_message_subtitle
      welcome_page_settings
      timezone
      dates
      end_date
      customer_name
      customer_number
      team_leader {
        id
      }
      support_employee {
        id
      }
      status
      audit_plan
      documents {
        id
        confidentiality
        audit_id
        author {
          id
          name
        }
        editor {
          id
          name
        }
        created_at
        updated_at
        dms_path
        file_name
        file_type
        mime_type
        lifespan
        permissions
      }
      participants {
        id
        user_id
        roles
        user {
          id
          name
          email
          source
          hr_number
          phone_number
          initials
          profile_picture
          title
          source_directory
          qualifications
          nationality
        }
      }
      ict_meeting
      meetings(order_by: [{ date: asc }, { start_time: asc }]) {
        id
        title
        description
        date
        start_time
        end_time
        meeting_id
        meeting_access_code
        status
        started_at
        ended_at
        lane_id
        teams_meeting
        teams_as_default_conference
        participants {
          id
          user_id
          meeting_id
          participant {
            id
            name
            email
            hr_number
            initials
            profile_picture
            title
            source_directory
            nationality
          }
        }
      }
    }
  }
`;
export const GET_AUDIT_MEETINGS_BY_SESSION_ID_QUERY = gql`
  query GET_AUDIT_MEETINGS($sessionId: String!) {
    ras_audit_meetings(
      order_by: [{ date: asc }, { start_time: asc }]
      where: { audit: { session_id: { _eq: $sessionId } } }
    ) {
      id
      title
      description
      date
      start_time
      end_time
      meeting_id
      meeting_access_code
      status
      started_at
      ended_at
      lane_id
      teams_meeting
      teams_as_default_conference
      participants {
        id
        user_id
        meeting_id
        participant {
          id
          name
          email
          hr_number
          initials
          profile_picture
          title
          source_directory
          nationality
        }
      }
    }
  }
`;

export const GET_AUDIT_BY_ID_QUERY = gql`
  query GET_AUDIT_BY_ID_QUERY($id: bigint!) {
    ras_audits_by_pk(id: $id) {
      id
      internal_acdc_url
      external_acdc_url
      acdc_id
      session_id
      start_date
      welcome_message_title
      welcome_message_subtitle
      invitations
      welcome_page_settings
      timezone
      dates
      default_conference_system
      company {
        id
        name
      }
      sales_office {
        id
        name
      }
      end_date
      customer_name
      customer_number
      ict_meeting
      customer_central_address
      locations
      certificates(order_by: { order: asc }) {
        id
        number
        standard
        type
        audit_identifier
        audit_guid
        certificate_guid
      }
      meetings {
        id
        title
        description
        date
        start_time
        end_time
        meeting_id
        meeting_access_code
        lane_id
        teams_meeting
        teams_as_default_conference
        participants {
          id
          user_id
          meeting_id
          participant {
            id
            name
            initials
            email
            hr_number
            profile_picture
            title
            source_directory
            nationality
          }
        }
      }
      team_leader {
        id
        name
        email
        hr_number
        profile_picture
        title
        source_directory
        nationality
        initials
      }
      support_employee {
        id
        name
        email
        hr_number
        initials
        profile_picture
        title
        source_directory
        nationality
      }
      status
      participants {
        id
        user_id
        roles
        user {
          id
          name
          email
          initials
          source
          hr_number
          profile_picture
          title
          source_directory
          nationality
          customer_number
        }
      }
      invitations
      audit_plan
    }
  }
`;

export const ADD_NEW_PARTICIPANT = gql`
  mutation ADD_NEW_PARTICIPANT(
    $auditId: Int!
    $userId: Int!
    $roles: [String]!
  ) {
    add_new_participant(auditId: $auditId, roles: $roles, userId: $userId) {
      data
      error
      success
    }
  }
`;

export const SET_STATUS_LIVE_MUTATION = gql`
  mutation SET_STATUS_LIVE_MUTATION($auditId: bigint!) {
    update_ras_audits_by_pk(
      pk_columns: { id: $auditId }
      _set: { status: "STATUS_LIVE" }
    ) {
      id
      status
    }
  }
`;

export const SET_STATUS_COMPLETED_MUTATION = gql`
  mutation SET_STATUS_COMPLETED_MUTATION($auditId: bigint!) {
    update_ras_audits_by_pk(
      pk_columns: { id: $auditId }
      _set: { status: "STATUS_COMPLETED" }
    ) {
      id
      status
    }
  }
`;
export const CREATE_TEAMS_MEETING = gql`
  mutation CREATE_ONLINE_MEETING(
    $id: Int!
    $endDateTime: String!
    $startDateTime: String!
    $title: String!
  ) {
    create_online_meeting(
      meetingId: $id
      endDateTime: $endDateTime
      startDateTime: $startDateTime
      subject: $title
    ) {
      data
      error
      success
    }
  }
`;
export const START_MEETING_MUTATION = gql`
  mutation START_MEETING_MUTATION(
    $meetingId: bigint!
    $startedAt: timestamptz!
  ) {
    update_ras_audit_meetings_by_pk(
      pk_columns: { id: $meetingId }
      _set: { status: "STARTED", started_at: $startedAt }
    ) {
      id
      status
      teams_as_default_conference
      teams_meeting
    }
  }
`;

export const END_MEETING_MUTATION = gql`
  mutation END_MEETING_MUTATION($meetingId: bigint!, $endedAt: timestamptz!) {
    update_ras_audit_meetings_by_pk(
      pk_columns: { id: $meetingId }
      _set: { status: "ENDED", ended_at: $endedAt }
    ) {
      id
      status
      teams_as_default_conference
      teams_meeting
    }
  }
`;

export const JOIN_QUERY = gql`
  query JOIN_QUERY($p: String!) {
    join(p: $p) {
      success
      data
      error
    }
  }
`;

export const GET_BACKGROUND_IMAGES_QUERY = gql`
  query GET_BACKGROUND_IMAGES_QUERY {
    ras_background_images(
      order_by: { id: asc }
      where: { deleted: { _eq: false } }
    ) {
      id
      type
      deleted
      name
      preview
    }
  }
`;

export const GET_BACKGROUND_IMAGES_BY_ID = gql`
  query GET_BACKGROUND_IMAGES_BY_ID($id: Int!) {
    ras_background_images(
      where: { id: { _eq: $id }, deleted: { _eq: false } }
      limit: 1
    ) {
      id
      type
      url
      deleted
    }
  }
`;

export const DELETE_BACKGROUND_IMAGES_BY_ID = gql`
  mutation DELETE_BACKGROUND_IMAGES_BY_ID($imageId: Int!) {
    update_ras_background_images_by_pk(
      pk_columns: { id: $imageId }
      _set: { deleted: true }
    ) {
      id
      deleted
    }
  }
`;

export const INSERT_RAS_BACKGROUND_IMAGES = gql`
  mutation INSERT_RAS_BACKGROUND_IMAGES(
    $name: String!
    $preview: String!
    $url: String!
  ) {
    insert_ras_background_images(
      objects: { url: $url, preview: $preview, name: $name }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_TRANSLATIONS = gql`
  query GET_TRANSLATIONS {
    ras_translations {
      translationProfile
      translationJSON
      isPublished
      code
      modifiedBy
      modifiedDate
      creationDate
    }
  }
`;

export const GET_TRANSLATION_BY_CODE = gql`
  query GET_TRANSLATIONS_BY_CODE($code: String!) {
    ras_translations(
      where: { _or: [{ code: { _eq: $code } }, { code: { _eq: "en" } }] }
    ) {
      code
      isPublished
      translationJSON
      translationProfile
      isPublished
    }
  }
`;

export const INSERT_LANGUAGE = gql`
  mutation ras_translations(
    $code: String
    $translationProfile: String
    $translationJSON: String
    $isPublished: Boolean
    $userName: String
  ) {
    insert_ras_translations(
      objects: [
        {
          code: $code
          translationProfile: $translationProfile
          translationJSON: $translationJSON
          isPublished: $isPublished
          modifiedBy: $userName
        }
      ]
    ) {
      affected_rows
    }
  }
`;
