import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan as error403Icon,
  faClipboard,
  faExclamationTriangle as errorDefaultIcon,
  faHome as backToHomeIcon,
  faSignOutAlt as signOutIcon,
  faSyncAlt as refreshIcon,
} from '@fortawesome/pro-light-svg-icons';
import useSignOut from '../../hooks/useSignOut';
import MailToButton from './MailToButton';
import { ERROR, LOCAL_STORAGE } from '../../common/constants';
import { AuditSessionContext } from '../../context/AuditSessionContext';

const ErrorPage = ({
  errorType = ERROR.IS_INTERNAL_ERROR,
  exception,
  additionalInformation = '',
  additionalObject = null,
}) => {
  const { t } = useTranslation();
  const signOut = useSignOut();
  // const auditSessionCtx = useContext(AuditSessionContext);

  const getRawError = (text = false) => {
    try {
      return `${new Date(Date.now()).toString()}${
        text ? '\n' : '%0D%0A'
      }${JSON.stringify(exception)}#${additionalInformation}`;
    } catch (err) {
      return `${ERROR.IS_INTERNAL_ERROR} - ${new Date(Date.now()).toString()}`;
    }
  };
  const getErrorPageRenderingValues = () => {
    switch (errorType) {
      case ERROR.IS_UNAUTHORIZED:
        return {
          errorTitle: t('ERROR_PAGES.403.TITLE'),
          errorIcon: error403Icon,
          errorMessage: t('ERROR_PAGES.403.MESSAGE'),
        };
      case ERROR.IS_ZOOM_ERROR:
      case ERROR.IS_INTERNAL_ERROR:
      default:
        return {
          errorTitle: t('ERROR_PAGES.500.TITLE'),
          errorIcon: errorDefaultIcon,
          errorMessage: t('ERROR_PAGES.500.MESSAGE'),
        };
    }
  };
  const { errorTitle, errorIcon, errorMessage } = getErrorPageRenderingValues();

  const reloadPage = () => {
    if (errorType === ERROR.IS_ZOOM_ERROR && additionalObject) {
      sessionStorage.setItem(
        LOCAL_STORAGE.ZOOM_MEETING,
        JSON.stringify(additionalObject)
      );
    }
    window.location.reload();
  };

  return (
    <div className="bg-white flex-1 items-center justify-center mt-28">
      <div className=" mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className=" text-5xl text-tuvr-dark-red ">
            <FontAwesomeIcon icon={errorIcon} /> {errorTitle}
          </h1>
          <p className=" mt-5 mx-auto text-xl text-gray-500">{errorMessage}</p>
          {additionalInformation && (
            <p className=" mt-5 mx-auto text-xl text-gray-500">
              {additionalInformation}
            </p>
          )}
          {errorType !== ERROR.IS_UNAUTHORIZED && (
            <>
              <p className="mt-5 mx-auto text-xl text-gray-500">
                <button
                  type="button"
                  className="text-tuvr-blue font-bold outline-none"
                  onClick={() => (window.location = '/')}
                >
                  <FontAwesomeIcon icon={backToHomeIcon} />{' '}
                  {t('GENERIC.RELOAD_APP')}
                </button>
              </p>
              <p className="text-tuvr-blue font-bold mt-5">
                <FontAwesomeIcon
                  icon={faClipboard}
                  className="mr-5 cursor-pointer"
                  onClick={() => {
                    try {
                      navigator.clipboard.writeText(getRawError(true));
                    } catch (error) {
                      console.log('copy to clipboard', error);
                    }
                  }}
                />
                <MailToButton
                  mailto={`mailto:?body=${getRawError()}&subject=Remote Audit Solution - Error`}
                />
              </p>
            </>
          )}
          {errorType === ERROR.IS_UNAUTHORIZED && (
            <p className="mt-5 mx-auto text-xl text-gray-500">
              <button
                type="button"
                className="text-tuvr-blue font-bold outline-none"
                onClick={() => signOut()}
              >
                <FontAwesomeIcon icon={signOutIcon} /> {t('SIGNOUT')}
              </button>
            </p>
          )}
          <p className=" mt-5 mx-auto text-xl text-gray-500">
            <button
              type="button"
              className="text-tuvr-blue font-bold outline-none"
              onClick={reloadPage}
            >
              <FontAwesomeIcon icon={refreshIcon} />
              {errorType === ERROR.IS_ZOOM_ERROR
                ? ` ${t('GENERIC.REFRESH_PAGE_REJOIN')}`
                : `${t('GENERIC.REFRESH_PAGE')}`}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  errorType: PropTypes.oneOf([
    ERROR.IS_NOT_FOUND,
    ERROR.IS_UNAUTHORIZED,
    ERROR.IS_INTERNAL_ERROR,
    ERROR.IS_ZOOM_ERROR,
  ]),
  exception: PropTypes.object,
  additionalInformation: PropTypes.string,
  additionalObject: PropTypes.object,
};

export default ErrorPage;
