import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './i18n';
import '@fontsource/roboto/300.css';
import './iconLibrary';

fetch('/config/config.json')
  .then((res) => res.json())
  .then((config) => {
    const msalInstance = new PublicClientApplication(config.idp.b2cSettings);
    ReactDOM.render(
      // <React.StrictMode>
      <App config={config} pca={msalInstance} />,
      // </React.StrictMode>,
      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
