import {
  SEARCH_AUDITS_ADVANCED,
  SEARCH_AUDITS_SIMPLE,
  SEARCH_USERS_SIMPLE,
  DASHBOARD_AUDITS_QUERY,
  GET_TRANSLATIONS,
} from './gql-model';

let apolloClient;

export const setApolloClient = (client) => {
  apolloClient = client;
};

export const fetchAuditsPage = (query, status, offset, orderBy) =>
  apolloClient.query({
    query: SEARCH_AUDITS_SIMPLE,
    variables: {
      query: `%${query}%`,
      exactQuery: query,
      status,
      offset,
      orderBy,
    },
    fetchPolicy: 'network-only',
  });

export const fetchAuditsPageAdvanced = (
  query,
  where,
  status,
  offset,
  orderBy
) =>
  apolloClient.query({
    query: SEARCH_AUDITS_ADVANCED,
    variables: {
      query: `%${query}%`,
      exactQuery: query,
      where,
      status,
      offset,
      orderBy,
    },
    fetchPolicy: 'network-only',
  });

export const fetchUsersPage = (query, source, offset, orderBy, admin, role) =>
  apolloClient.query({
    query: SEARCH_USERS_SIMPLE,
    variables: {
      query: `%${query}%`,
      source,
      offset,
      orderBy,
      admin,
      role,
    },
    fetchPolicy: 'network-only',
  });

export const fetchDashboardAuditsPage = (
  query,
  status,
  offset,
  orderBy,
  userId
) =>
  apolloClient.query({
    query: DASHBOARD_AUDITS_QUERY,
    variables: {
      query: `%${query}%`,
      status,
      offset,
      orderBy,
      userId,
    },
    fetchPolicy: 'network-only',
  });

export const fetchLanguagesPage = (query, source, offset, orderBy) =>
  apolloClient.query({
    query: GET_TRANSLATIONS,
    variables: {
      query: `%${query}%`,
      source,
      offset,
      orderBy,
    },
    fetchPolicy: 'network-only',
  });
