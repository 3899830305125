import React, {
  useReducer,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import '../pages/ZoomTestPage.css';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  AUDIT_ROLE,
  AUDIT_SESSION,
  AUDIT_SOURCE,
  AUDIT_STATUS,
  CHAT,
} from '../common/constants';
import NotFound from '../components/common/NotFound';
import {
  isSupportCheck,
  isLeaderCheck,
  userAsParticipant,
  getLeader,
  getLeaderDeputy,
  getAuditors,
  isTLeaderCheck,
} from '../components/audit-session/auditSessionFunctions';
import {
  ADD_NEW_PARTICIPANT,
  GET_AUDIT_SESSION_DOCUMENTS,
  GET_AUDIT_MEETINGS_BY_SESSION_ID_QUERY,
  GET_AUDIT_SESSION_PARTICIPANTS_QUERY,
} from '../components/audit-session/gql-model';
import { RealTimeContext } from './RealTimeContextProvider';
import { UserContext } from './UserContextProvider';
import { NotificationContext } from './NotificationsContext';
import AuditInterrupted from '../components/audit-session/AuditInterrupted';
import AuditClosed from '../components/audit-session/AuditClosed';
import AuditDraft from '../components/audit-session/AuditDraft';
import { ChangeTrackerContext } from './ChangeTrackerContext';
import { notifyDurationRaiseHand } from '../assets/public/config/config.json';

export const AuditSessionContext = React.createContext(null);

const AuditSessionContextProvider = ({
  children,
  user,
  sessionId,
  initAudit,
  mobile,
  forceUpdate,
}) => {
  const { realtimeSession } = useContext(RealTimeContext);
  const ctxUser = useContext(UserContext);
  const { notify } = useContext(NotificationContext);
  const client = useApolloClient();
  const auditSessionSubscription = useRef(null);
  const handRaisedSessionSubscription = useRef(null);
  const [shareMode, setShareMode] = useState(0);
  const [isMeetingApproved, setIsMeetingApproved] = useState(false);
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const customer = useRef(null);
  const auditSessionTopic = `${AUDIT_SESSION.WAMP_SESSION_AUDIT}.${sessionId}`;
  const customerBusinessNoAdded = useRef(false);
  const [pinnedParticipants, setPinnedParticipants] = useState([]);
  const [handRaisedParticipants, setHandRaisedParticipants] = useState([]);
  const [currentMeeting, setCurrentMeeting] = useState();
  const map = useRef(new Map());
  const rasedHandMap = useRef(new Map());
  const { registerSubscriber } = useContext(ChangeTrackerContext);
  const { t } = useTranslation();

  const publishHandRaisedNotification = () => {
    const payload = {
      userId: ctxUser.user?.id,
      timestamp: Date.now(),
      name: ctxUser.user?.name,
    };
    try {
      realtimeSession.publish(
        `${AUDIT_SESSION.WAMP_HANDRAISED_AUDIT}.${sessionId}`,
        [],
        payload,
        {
          exclude_me: false,
        }
      );
    } catch (error) {
      console.log('publishHandRaisedNotification error:', error);
    }
  };

  const handleHandRaised = async (args, body) => {
    const { userId, name } = body;
    if (rasedHandMap.current.has(userId)) {
      rasedHandMap.current.delete(userId);
    } else {
      rasedHandMap.current.set(userId, body);
      notify(name, 'hand-raised', false, notifyDurationRaiseHand);
    }
    const rasedHandArray = Array.from(rasedHandMap.current.values());
    const arrayWithOrderedIndexes = rasedHandArray.sort(function (x, y) {
      return x.timestamp - y.timestamp;
    });
    rasedHandArray.map((currElement, index) => (currElement.count = index + 1));
    setHandRaisedParticipants(rasedHandArray);
  };

  useEffect(() => {
    const subscribeHandRaisedSession = async () => {
      try {
        realtimeSession
          .subscribe(
            `${AUDIT_SESSION.WAMP_HANDRAISED_AUDIT}.${sessionId}`,
            handleHandRaised
          )
          .then(
            (subscription) =>
              (handRaisedSessionSubscription.current = subscription)
          )
          .catch((e) => console.log(e));
        return true;
      } catch (error) {
        console.log('subscribeHandRaisedSession error:', error);
        return false;
      }
    };

    if (realtimeSession && !handRaisedSessionSubscription.current) {
      subscribeHandRaisedSession();
    }

    return () => {
      if (realtimeSession && handRaisedSessionSubscription.current) {
        try {
          realtimeSession.unsubscribe(handRaisedSessionSubscription.current);
        } catch (err) {
          console.log(
            `realtimeSession - unsubscript from ${handRaisedSessionSubscription.current}`
          );
        } finally {
          handRaisedSessionSubscription.current = null;
        }
      }
    };
  }, [realtimeSession]);

  const onPinParticipant = (pinnedUser) => {
    if (map.current.has(pinnedUser.userId)) {
      map.current.delete(pinnedUser.userId);
    } else {
      map.current.set(pinnedUser.userId, pinnedUser);
    }
    setPinnedParticipants(Array.from(map.current.values()));
  };

  const onRemoveParticipant = (userId) => {
    if (map.current.has(userId)) {
      map.current.delete(userId);
      setPinnedParticipants(Array.from(map.current.values()));
    }
  };

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      const { type, payload } = action;
      switch (type) {
        case 'AUDIT_UPDATED': {
          const updatedAudit = payload;
          return {
            ...currentState,
            audit: {
              ...currentState?.audit,
              status:
                currentState?.audit.id === updatedAudit.id
                  ? updatedAudit.status
                  : currentState?.audit.status,
            },
          };
        }
        case 'MEETING_UPDATED': {
          const updatedMeeting = payload;
          if (currentState?.audit.id === updatedMeeting.audit_id) {
            return {
              ...currentState,
              meetings: currentState?.meetings.map((meeting) =>
                meeting.id === updatedMeeting.id ? updatedMeeting : meeting
              ),
            };
          }
          return currentState;
        }
        case 'DOCUMENT_INSERTED': {
          const newDocument = payload;
          if (currentState?.audit.id === newDocument.audit_id) {
            return {
              ...currentState,
              documents: [...currentState?.documents, newDocument],
            };
          }
          return currentState;
        }
        case 'DOCUMENT_UPDATED': {
          const updatedDocument = payload;
          if (currentState?.audit.id === updatedDocument.audit_id) {
            return {
              ...currentState,
              documents: currentState?.documents.map((document) =>
                document.id === updatedDocument.id ? updatedDocument : document
              ),
            };
          }
          return currentState;
        }
        case 'DOCUMENT_DELETED': {
          const deletedDocumentId = payload;
          if (
            currentState?.documents &&
            currentState?.documents.length &&
            currentState?.documents.find(
              (document) => document.id === deletedDocumentId
            )
          ) {
            return {
              ...currentState,
              documents: currentState?.documents.filter(
                (document) => document.id !== deletedDocumentId
              ),
            };
          }
          return currentState;
        }
        case 'PARTICIPANT_INSERTED': {
          const newParticipant = payload;
          if (currentState?.audit.id === newParticipant.audit_id) {
            return {
              ...currentState,
              participants: [...currentState?.participants, newParticipant],
            };
          }
          return currentState;
        }
        case 'PARTICIPANT_UPDATED': {
          const updatedParticipant = payload;
          if (currentState?.audit.id === updatedParticipant.audit_id) {
            return {
              ...currentState,
              participants: currentState?.participants.map((participant) =>
                participant.id === updatedParticipant.id
                  ? updatedParticipant
                  : participant
              ),
            };
          }
          return currentState;
        }
        case 'PARTICIPANT_DELETED': {
          const deletedParticipantId = payload;
          if (
            currentState?.participants &&
            currentState?.participants.length &&
            currentState?.participants.find(
              (participant) => participant.id === deletedParticipantId
            )
          ) {
            return {
              ...currentState,

              participants: currentState?.participants.filter(
                (participant) => participant.id !== deletedParticipantId
              ),
            };
          }
          return currentState;
        }
        case 'MEETING_PARTICIPANT_INSERTED': {
          const newParticipant = payload;
          if (
            currentState?.meetings &&
            currentState?.meetings.length &&
            currentState?.meetings.find(
              (meeting) => meeting.id === newParticipant.meeting_id
            )
          ) {
            const { extra: affectedMeeting } = action;
            return {
              ...currentState,
              meetings: currentState?.meetings.map((meeting) =>
                meeting.id === newParticipant.meeting_id
                  ? {
                      ...meeting,
                      participants: [
                        ...meeting.participants,
                        affectedMeeting?.participants.find(
                          (participant) => participant.id === newParticipant.id
                        ),
                      ],
                    }
                  : meeting
              ),
            };
          }
          return currentState;
        }
        case 'MEETING_PARTICIPANT_DELETED': {
          const deletedParticipantId = payload;
          if (
            currentState?.meetings &&
            currentState?.meetings.length &&
            currentState?.meetings.find((meeting) =>
              meeting.participants.find(
                (participant) => participant.id === deletedParticipantId
              )
            )
          ) {
            return {
              ...currentState,
              meetings: currentState?.meetings.map((meeting) => ({
                ...meeting,
                participants: meeting.participants.filter(
                  (participant) => participant.id !== deletedParticipantId
                ),
              })),
            };
          }
          return currentState;
        }

        default:
          console.log(action);
          break;
      }
    },
    {
      audit: initAudit,
      participants: initAudit.participants,
      documents: initAudit.documents,
      meetings: initAudit.meetings,
    }
  );

  const isAuditor = state?.participants?.some(
    (p) => p.user_id === user.id && p.user.source === AUDIT_SOURCE.TUV
  );

  const isCustomer = state?.participants?.some(
    (p) => p.user_id === user.id && p.user.source === AUDIT_SOURCE.CUSTOMER
  );

  const handleChangeTrackerStrategy = (data) => {
    const { type, payload, extra } = data;
    dispatch({ type, payload, extra });
  };

  registerSubscriber.current = handleChangeTrackerStrategy;
  const handleRefetchStrategy = async (
    args,
    kwargs,
    details,
    isLocalUpdate = false
  ) => {
    try {
      const { REFETCH_TYPES } = AUDIT_SESSION;
      const { type, payload } = kwargs;
      // TODO: still to "much" to fetch for documents / meetings...
      switch (type) {
        case REFETCH_TYPES.DEFAULT_MEETING_REQUEST:
          if (isAuditor) {
            customer.current = payload;
            setIsConfirmDialog(true);
          }
          break;
        case REFETCH_TYPES.DEFAULT_MEETING_RESPONSE:
          {
            const { msg, msgType, id } = payload;
            if (ctxUser.user?.id === id) {
              setIsMeetingApproved(msgType === 'success');
              notify(msg, msgType);
            }
            setIsConfirmDialog(false);
          }
          break;
        default:
          console.log('No type found', type);
          break;
      }
    } catch (error) {
      console.log('auditsessionctx handleRefetchStrategy error', error);
    } finally {
      console.log(
        'auditsessionctx handleRefetchStrategy',
        args,
        kwargs,
        details
      );
    }
  };

  const publishRefetchNotification = (
    type,
    payload,
    localUpdateNeeded = true
  ) => {
    try {
      console.log('publish notification', type, payload);
      // console.log('FIREEEEE!!!');
      realtimeSession.publish(
        auditSessionTopic,
        [],
        { type, payload },
        {
          exclude_me: true,
        }
      );
      handleRefetchStrategy(null, { type, payload }, null, true);
    } catch (error) {
      console.log('auditsessionctx publishRefetchNotification', error);
    }
  };

  const addNewParticipant = async (userId, auditId, roles) => {
    const {
      data: {
        add_new_participant: { success },
      },
    } = await client.mutate({
      mutation: ADD_NEW_PARTICIPANT,
      variables: { auditId, userId, roles },
    });

    if (success) {
      console.log(
        'customerBusinessNoAdded done, let s fire it after realtimesession is there'
      );
      return true;
    }
  };
  useEffect(() => {
    const addCustomerToAuditWithSameCoNo = async () => {
      if (
        !(
          state?.audit && state?.participants.some((p) => p.user_id === user.id)
        ) &&
        !customerBusinessNoAdded.current
      ) {
        customerBusinessNoAdded.current = true;
        await addNewParticipant(user.id, state?.audit.id, []);
      }
    };

    const isParticipant =
      state?.audit && state?.participants?.some((p) => p.user_id === user.id);

    if (
      realtimeSession &&
      !isParticipant &&
      state?.audit.customer_number === user.customer_number
    ) {
      // add participant
      addCustomerToAuditWithSameCoNo();
    }
  }, [realtimeSession]);

  useEffect(() => {
    const subscribeToAuditSession = async () => {
      try {
        console.log(`auditsessionctx - subscribe to ${auditSessionTopic}`);
        realtimeSession
          .subscribe(auditSessionTopic, handleRefetchStrategy, {
            match: 'exact',
          })
          .then(
            (subscription) => (auditSessionSubscription.current = subscription)
          )
          .catch((e) => console.log(e));
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    };

    if (realtimeSession && !auditSessionSubscription.current) {
      subscribeToAuditSession();
    }

    return () => {
      if (realtimeSession && auditSessionSubscription.current) {
        try {
          realtimeSession.unsubscribe(auditSessionSubscription.current);
        } catch (err) {
          console.log(
            `auditsessionctx - unsubscript from ${auditSessionSubscription.current}`
          );
        } finally {
          auditSessionSubscription.current = null;
        }
      }
    };
  }, [realtimeSession]);

  const hasAccess =
    state?.audit && state?.participants?.some((p) => p.user_id === user.id);

  if (!hasAccess) {
    return <NotFound />;
  }
  if (state?.audit.status === AUDIT_STATUS.INTERRUPTED) {
    return <AuditInterrupted />;
  }
  if (state?.audit.status === AUDIT_STATUS.DELETED) {
    return <NotFound />;
  }
  if (state?.audit.status === AUDIT_STATUS.CLOSED) {
    return <AuditClosed />;
  }
  if (state?.audit?.status === AUDIT_STATUS.IN_DRAFT) {
    return <AuditDraft />;
  }

  const isSupport = isSupportCheck(state?.participants, user);
  const isLeader = isLeaderCheck(state?.participants, user);
  const isTLeader = isTLeaderCheck(state?.participants, user);
  const leader = getLeader(state?.participants);
  const leaderDeputy = getLeaderDeputy(state?.participants);
  const auditors = getAuditors(state?.participants);

  return (
    <AuditSessionContext.Provider
      value={{
        audit: state?.audit,
        isLeader,
        isSupport,
        isTLeader,
        participants: state?.participants,
        documents: state?.documents,
        user,
        meetings: state?.meetings,
        sessionId,
        mobile,
        defaultMeeting: state?.meetings.find(
          (meeting) => meeting.lane_id === 0
        ),
        publishRefetchNotification,
        publishHandRaisedNotification,
        participant: userAsParticipant(state?.participants, user),
        isMeetingApproved,
        setIsMeetingApproved,
        isConfirmDialog,
        setIsConfirmDialog,
        customer,
        isAuditor,
        addNewParticipant,
        pinnedParticipants,
        setPinnedParticipants,
        onPinParticipant,
        handRaisedParticipants,
        setHandRaisedParticipants,
        currentMeeting,
        setCurrentMeeting,
        leader,
        leaderDeputy,
        auditors,
        shareMode,
        setShareMode,
        onRemoveParticipant,
        isCustomer,
      }}
    >
      {children}
    </AuditSessionContext.Provider>
  );
};

AuditSessionContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  initAudit: PropTypes.object,
  user: PropTypes.object,
  sessionId: PropTypes.string,
  mobile: PropTypes.bool,
  forceUpdate: PropTypes.func,
};

export default AuditSessionContextProvider;
