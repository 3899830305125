import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes, { number } from 'prop-types';
import { useMsal } from '@azure/msal-react';
import { AppContext } from '../AppContextProvider';
import { getToken } from '../common/auth-utils';

export const ChangeTrackerContext = createContext(null);
const ChangeTrackerContextProvider = ({ auditId, children }) => {
  const registerSubscriber = useRef((data) => console.log(data));
  const wsClient = useRef(null);
  const { instance, accounts } = useMsal();
  const account = accounts.length > 0 ? accounts[0] : undefined;
  const { config } = useContext(AppContext);

  const isEventRelevant = (payload, extra) => {
    try {
      const auditIdOfEvent = payload?.audit_id ? payload.audit_id : payload.id;
      const auditIdOfExtra = extra?.audit_id ? extra?.audit_id : 0;
      return auditIdOfEvent === auditId || auditIdOfExtra === auditId;
    } catch (err) {
      console.log('[ChangeTrackerRelevantCheck]', err);
      return false;
    }
  };
  const onOpen = () => {
    console.log(`[WS: CONNECTED]`);
  };

  const onClose = (evt) => {
    console.log(`[WS: CLOSE]: ${evt.code} | ${evt.reason} | ${evt.message}`);
  };

  const onError = (err) => {
    console.log(`[WS: ERROR]:${err.message}`);
    console.log(`[WS: RECONNECTING]`);
    setTimeout(() => {
      // eslint-disable-next-line no-use-before-define
      connect(true);
    }, 2000);
  };
  const onMessage = (evt) => {
    if (evt.data === 'ping') {
      if (wsClient.readyState === WebSocket.OPEN) {
        wsClient.send('pong');
      }
      return;
    }
    if (registerSubscriber.current) {
      try {
        const data = JSON.parse(evt.data);
        if (isEventRelevant(data?.payload, data?.extra)) {
          console.log('[CTR - onMessage]:relevant', evt);
          registerSubscriber.current(JSON.parse(evt.data));
        } else {
          console.log('[CTR - onMessage]:not relevant', evt);
        }
      } catch (err) {
        console.log('[CTR - onMessage]', evt, err);
      }
    }
  };
  const connect = async () => {
    const token = await getToken(instance, config, account);
    const { accessToken } = token;
    const url = `${config.changeTrackerEndpoint}?token=${accessToken}`;
    wsClient.current = new WebSocket(url);

    wsClient.current.addEventListener('open', onOpen);
    wsClient.current.addEventListener('error', onError);
    wsClient.current.addEventListener('message', onMessage);
    wsClient.current.addEventListener('close', onClose);
  };
  useEffect(() => {
    if (!wsClient.current || ![0, 1].includes(wsClient.current.readyState)) {
      connect();
    }
    return () => {
      console.log(`[WS: DESTROY]`);
      wsClient.current.removeEventListener('open', onOpen);
      wsClient.current.removeEventListener('error', onError);
      wsClient.current.removeEventListener('message', onMessage);
      wsClient.current.removeEventListener('close', onClose);
      if (![2, 3].includes(wsClient.current.readyState)) {
        wsClient.current.close();
      }
    };
  }, []);

  return (
    <ChangeTrackerContext.Provider value={{ registerSubscriber }}>
      {children}
    </ChangeTrackerContext.Provider>
  );
};

ChangeTrackerContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  auditId: PropTypes.number,
};

export default ChangeTrackerContextProvider;
