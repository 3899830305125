/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContextProvider';
import UserAvatar from '../common/UserAvatar';
import useSignOut from '../../hooks/useSignOut';

export const UserAccount = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const signOut = useSignOut();

  const classNamesFn = (...classes) => classes.filter(Boolean).join(' ');
  const userNavigation = [
    {
      name: 'USERPROFILE',
      linkTo: '/profile',
      handler: () => console.log(user),
    },
    {
      name: 'SIGNOUT',
      linkTo: '#',
      handler: () => signOut(),
    },
  ];

  if (user == null) {
    return 'Loading';
  }
  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <div>
          <div>
            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span className="sr-only">Open user menu</span>
              <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
                <UserAvatar user={user} hideOnlineStatus hideBadge />
              </span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map(({ name, linkTo, handler }) => (
                <Menu.Item key={name}>
                  {({ active }) => (
                    <Link
                      to={linkTo}
                      role="button"
                      className={classNamesFn(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                      )}
                      onClick={handler}
                      tabIndex={0}
                      onKeyUp={(e) => console.log('key up', e)}
                    >
                      {t(name)}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};
UserAccount.propTypes = {};
export default UserAccount;
