import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';

const typeOfBadge = (user) => {
  if (
    user.auditRole.teamLeader ||
    user.auditRole.teamLeaderDeputy ||
    user.auditRole.mainContactPerson
  ) {
    return 'bg-yellow-500';
  }
  if (user.auditRole.support) {
    return 'bg-purple-700';
  }
};

const UserAvatar = ({
  user,
  large,
  sm,
  md,
  xmd,
  hideOnlineStatus,
  hideBadge,
  initials,
  withSrc,
  isOnline = false,
}) => {
  const renderBadge = () => {
    if (user.auditRole.teamLeader) {
      return 'TL';
    }
    if (user.auditRole.teamLeaderDeputy) {
      return 'LD';
    }
    if (user.auditRole.mainContactPerson) {
      return 'MC';
    }
    if (user.auditRole.support) {
      return <FontAwesomeIcon icon={faCog} style={{ color: '##ffffff' }} />;
    }
  };

  const renderUserBadge = () => {
    if (hideBadge) {
      return null;
    }
    if (
      user.auditRole.support ||
      user.auditRole.teamLeader ||
      user.auditRole.teamLeaderDeputy ||
      user.auditRole.mainContactPerson
    ) {
      return (
        <div
          className={` absolute -top-2 -right-2 block h-7 w-7 rounded-full ring-white 
              ${typeOfBadge(user)}
       text-gray-700 text-xs bg-white flex items-center justify-center `}
        >
          <div className="flex items-center justify-center text-base-09 font-bold leading-none text-white">
            {renderBadge(user)}
          </div>
        </div>
      );
    }
    return '';
  };

  const getSize = () => {
    if (large) {
      return 'w-48 h-48';
    }
    if (sm) {
      return 'w-11 h-11';
    }
    if (md) {
      return 'w-14 h-14';
    }
    if (xmd) {
      return 'w-28	h-28	';
    }

    return 'w-16 h-16';
  };

  const getFontSize = () => {
    if (large) {
      return 'text-base-4x';
    }
    if (sm) {
      return 'text-base-12';
    }
    if (md) {
      return 'text-sm';
    }
    if (xmd) {
      return 'text-sm';
    }

    return 'text-sm';
  };

  return (
    <>
      <div
        className={classNames(
          hideOnlineStatus ? '' : 'rounded-full ring-4',
          isOnline ? 'ring-tuvr-green' : 'ring-tuvr-red',
          getSize()
        )}
      >
        <div className="inline-block relative">
          <div
            className={classNames(
              'inline-flex items-center justify-center rounded-full border-none bg-gray-500',
              getSize()
            )}
          >
            {(user.profile_picture && !user.initials && !initials) ||
            withSrc ? (
              <img
                className=" inline-block  rounded-full transform object-cover h-full"
                src={withSrc || user.profile_picture}
                alt="userAvatar"
              />
            ) : (
              <div
                className={classNames(
                  'text-sm font-medium leading-none text-white ',
                  getFontSize()
                )}
              >
                {`${
                  user?.name
                    ?.match(/\b(\w)/g)
                    .slice(0, 2)
                    .join('') || user.name
                }`}
              </div>
            )}
            {renderUserBadge(user)}
          </div>
        </div>
      </div>
    </>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object,
  large: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  xmd: PropTypes.bool,
  hideOnlineStatus: PropTypes.bool,
  hideBadge: PropTypes.bool,
  initials: PropTypes.bool,
  withSrc: PropTypes.string,
  isOnline: PropTypes.bool,
};

export default UserAvatar;
