/* eslint-disable import/prefer-default-export */
const initialState = {};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default configReducer;
