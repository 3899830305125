import { gql, useQuery } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import ja from 'date-fns/locale/ja';
import hu from 'date-fns/locale/hu';
import dayjs from 'dayjs';

require('dayjs/locale/de');
require('dayjs/locale/ja');
require('dayjs/locale/hu');

const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY($oid: uuid) {
    ras_users(where: { oid: { _eq: $oid } }) {
      id
      name
      email
      active
      # settings
      language
      locale
      terms_accepted_at
      profile_picture
      customer_number
      time_zone
      background_image_id
      admin
      admin_roles
      role {
        id
        key
        translations
      }
    }
  }
`;

function useCurrentUser() {
  const { i18n } = useTranslation();
  const { accounts } = useMsal();
  const account = accounts.length > 0 ? accounts[0] : undefined;
  const { data, error, loading } = useQuery(CURRENT_USER_QUERY, {
    variables: { oid: account?.idTokenClaims?.oid },
  });
  const [currentUser, setCurrentUser] = useState(null);
  const setUserLocale = (localePrefix, localeObject) => {
    registerLocale(localePrefix, localeObject);
    setDefaultLocale(localePrefix);
    dayjs.locale(localePrefix);
  };

  useEffect(() => {
    if (!loading && !error) {
      const [user] = data && data.ras_users;
      if (user && !Object.is(user, currentUser)) {
        setCurrentUser(user);
      }
      const language = currentUser && currentUser.language;
      const locale = currentUser && currentUser.locale;
      if (language && language !== i18n.language) {
        i18n.changeLanguage(language);
      }

      if (language && language.startsWith('de')) {
        registerLocale('de', de);
        setDefaultLocale('de');
        dayjs.locale('de');
      } else if (language && language.startsWith('hu')) {
        registerLocale('hu', hu);
        setDefaultLocale('hu');
        dayjs.locale('hu');
      } else if (language && language.startsWith('ja')) {
        registerLocale('ja', ja);
        setDefaultLocale('ja');
        dayjs.locale('ja');
      }
      if (locale) {
        switch (locale) {
          case 'en-US':
            setUserLocale('en', en);
            break;
          case 'de-DE':
            setUserLocale('de', de);
            break;
          default:
            break;
        }
      }
    }
  }, [currentUser, loading, data, i18n, error]);

  return [currentUser, setCurrentUser, error, loading];
}

export default useCurrentUser;
