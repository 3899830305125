import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/TUV_R_Trademark_1_Claim_sRGB_blue.svg';
import { UserAccount } from './UserAccount';

const Header = ({ appTitle }) => {
  const location = useLocation();
  const isAuditSessionRoute = location.pathname.includes('audit-session');
  return (
    <>
      {!isAuditSessionRoute && (
        <nav className="bg-white sticky top-0 shadow z-20">
          <div className="pr-12 pl-8">
            <div className="flex justify-between h-24">
              <div className="flex items-center">
                <Link to="/">
                  <img src={logo} alt="TÜV Rheinland Logo" width="140" />
                </Link>
              </div>
              <div className="flex md:flex-row aud:flex-col md:gap-6 aud:gap-2 items-center justify-center">
                <div className="text-tuv-subtitle font-bold">{appTitle}</div>
              </div>
              <div className="flex items-center gap-14">
                <UserAccount />
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

Header.propTypes = {
  appTitle: PropTypes.string.isRequired,
};
export default Header;
