import { call, put, select, takeEvery } from 'redux-saga/effects';
import set from 'lodash.set';
import { getAuditListPageSuccess } from '../actions';
import { fetchAuditsPage, fetchAuditsPageAdvanced } from '../api/api';
import {
  GET_AUDIT_LIST_PAGE,
  RESET_ADVANCED_FILTER,
  SET_ADVANCED_FILTER,
  SET_AUDIT_LIST_PAGE,
  SET_AUDIT_LIST_QUERY,
  SET_AUDIT_LIST_SORT,
  SET_AUDIT_LIST_STATUS,
} from '../constants';

const querySelector = (state) => state.auditList.query;
const statusSelector = (state) => state.auditList.status;
const pageSelector = (state) => state.auditList.page;
const sortDirSelector = (state) => state.auditList.sortDir;
const sortPropSelector = (state) => state.auditList.sortProp;
const advancedSearchSelector = (state) => state.auditList.advancedFilterOpen;
const advancedFilterSelector = (state) => state.auditList.advancedFilter;

const advancedFilterGroup = (conditions, advancedFilterArray, searchQuery) => {
  if (advancedFilterArray && advancedFilterArray.length > 0) {
    const conditionsOr = [];
    advancedFilterArray.forEach((value) =>
      conditionsOr.push(searchQuery(value))
    );
    conditions.push({ _or: conditionsOr });
  }
};

const buildFilter = (advancedFilter) => {
  const conditions = [];

  advancedFilterGroup(conditions, advancedFilter.trCompanies, (object) => ({
    company: { id: { _eq: object.id } },
  }));

  advancedFilterGroup(conditions, advancedFilter.supportUsers, (object) => ({
    support_employee: { name: { _ilike: `%${object.value}%` } },
  }));

  advancedFilterGroup(conditions, advancedFilter.certNos, (object) => ({
    certificates: { number: { _ilike: `%${object.value}%` } },
  }));

  advancedFilterGroup(conditions, advancedFilter.auditIds, (object) => ({
    certificates: { audit_identifier: { _ilike: `%${object.value}%` } },
  }));

  advancedFilterGroup(conditions, advancedFilter.auditTypes, (object) => ({
    certificates: { type: { _ilike: `%${object.value}%` } },
  }));

  advancedFilterGroup(conditions, advancedFilter.standards, (object) => ({
    certificates: { standard: { _ilike: `%${object.value}%` } },
  }));

  if (advancedFilter.from) {
    conditions.push({ end_date: { _gte: advancedFilter.from.toDateString() } });
  }

  if (advancedFilter.to) {
    conditions.push({ end_date: { _lte: advancedFilter.to.toDateString() } });
  }

  return conditions.length > 0 ? { _and: conditions } : undefined;
};

const getOrderBy = (sortProp, sortDir) =>
  set({}, sortProp, sortDir === 1 ? 'asc' : 'desc');

export function* handleAuditListPageSaga() {
  try {
    const query = yield select(querySelector);
    const status = yield select(statusSelector);
    const isAdvancedSearch = yield select(advancedSearchSelector);
    const advancedFilter = yield select(advancedFilterSelector);
    const page = yield select(pageSelector);
    const sortDir = yield select(sortDirSelector);
    const sortProp = yield select(sortPropSelector);
    const gqlFilter = yield call(buildFilter, advancedFilter);
    const offset = (page - 1) * 10;
    const response = isAdvancedSearch
      ? yield call(
          fetchAuditsPageAdvanced,
          query,
          gqlFilter,
          status,
          offset,
          sortProp ? getOrderBy(sortProp, sortDir) : undefined
        )
      : yield call(
          fetchAuditsPage,
          query,
          status,
          offset,
          sortProp ? getOrderBy(sortProp, sortDir) : undefined
        );
    yield put(
      getAuditListPageSuccess(
        response.data.ras_audits,
        response.data.ras_audits_aggregate.aggregate.count
      )
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call(console.error, error);
  }
}

export function* auditListPageSaga() {
  yield takeEvery(
    [
      GET_AUDIT_LIST_PAGE,
      SET_AUDIT_LIST_QUERY,
      SET_AUDIT_LIST_STATUS,
      SET_ADVANCED_FILTER,
      SET_AUDIT_LIST_PAGE,
      SET_AUDIT_LIST_SORT,
      RESET_ADVANCED_FILTER,
    ],
    handleAuditListPageSaga
  );
}
