import { useMsal } from '@azure/msal-react';
import React, { useContext, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { getApolloClient } from '../apollo';
import { AppContext } from '../AppContextProvider';
import UserContextProvider from '../context/UserContextProvider';
import Router from '../components/Router';
import { setApolloClient } from '../api/api';
import configureStore from '../configureStore';
import NotificationContextProvider from '../context/NotificationsContext';
import ShowLongTextContextProvider from '../context/ShowLongTextContext';
import AgendaContextProvider from '../context/AgendaContext';

const AppShell = () => {
  const { instance, accounts } = useMsal();
  const { config } = useContext(AppContext);
  const { store } = configureStore({ config });
  const account = accounts.length > 0 ? accounts[0] : undefined;
  const [apolloClient] = useState(getApolloClient(config, instance, account));

  setApolloClient(apolloClient);
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ShowLongTextContextProvider>
          <NotificationContextProvider>
            <UserContextProvider>
              <AgendaContextProvider>
                <Router />
              </AgendaContextProvider>
            </UserContextProvider>{' '}
          </NotificationContextProvider>
        </ShowLongTextContextProvider>
      </ApolloProvider>
    </Provider>
  );
};

export { AppShell };
