import { MsalProvider, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { EventType, InteractionType } from '@azure/msal-browser';
import PropTypes from 'prop-types';
import { AuthorizedContent } from './AuthorizedContent';
import { forgotPasswordRequest } from './authConfig.js';
import AppContextProvider from './AppContextProvider';
import ErrorBoundary from './components/common/ErrorBoundary';

const App = ({ pca, config }) => {
  const { instance } = useMsal();
  // const { t } = useTranslation();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          event.error.errorMessage.indexOf('AADB2C90118') > -1
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(forgotPasswordRequest);
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(forgotPasswordRequest).catch(() => {});
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <ErrorBoundary>
      <AppContextProvider config={config}>
        <MsalProvider instance={pca}>
          <AuthorizedContent />
        </MsalProvider>
      </AppContextProvider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  pca: PropTypes.object,
  config: PropTypes.object,
};

export default App;
