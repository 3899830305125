import gql from 'graphql-tag';

export const SEARCH_AUDITS_SIMPLE = gql`
  query SEARCH_AUDITS_SIMPLE(
    $query: String!
    $exactQuery: String
    $status: String
    $offset: Int!
    $orderBy: [ras_audits_order_by!]
  ) {
    ras_audits_aggregate(
      where: {
        _and: [
          { status: { _eq: $status } }
          {
            _or: [
              { ras_id: { _eq: $exactQuery } }
              { company: { name: { _ilike: $query } } }
              { sales_office: { name: { _ilike: $query } } }
              { support_employee: { name: { _ilike: $query } } }
              { customer_name: { _ilike: $query } }
              { certificates: { standard: { _ilike: $query } } }
              { certificates: { number: { _ilike: $query } } }
              { certificates: { type: { _ilike: $query } } }
              { certificates: { audit_identifier: { _ilike: $query } } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    ras_audits(
      order_by: $orderBy
      offset: $offset
      limit: 10
      where: {
        _and: [
          { status: { _eq: $status } }
          {
            _or: [
              { ras_id: { _eq: $exactQuery } }
              { company: { name: { _ilike: $query } } }
              { sales_office: { name: { _ilike: $query } } }
              { support_employee: { name: { _ilike: $query } } }
              { customer_name: { _ilike: $query } }
              { certificates: { standard: { _ilike: $query } } }
              { certificates: { number: { _ilike: $query } } }
              { certificates: { type: { _ilike: $query } } }
              { certificates: { audit_identifier: { _ilike: $query } } }
            ]
          }
        ]
      }
    ) {
      id
      acdc_id
      external_acdc_url
      internal_acdc_url
      combi_audit
      company {
        id
        name
        code
      }
      sales_office {
        id
        name
        code
      }
      support_employee {
        id
        name
        email
        initials
        hr_number
        profile_picture
      }
      customer_name
      customer_central_address
      certificates(order_by: { order: asc }) {
        id
        number
        standard
        type
        audit_identifier
      }
      session_id
      start_date
      end_date
      status
      ras_id
      documents {
        author {
          name
          source
        }
        confidentiality
        file_name
        lifespan
        permissions
        created_at
      }
    }
  }
`;

export const SEARCH_AUDITS_ADVANCED = gql`
  query SEARCH_AUDITS_ADVANCED(
    $query: String!
    $exactQuery: String
    $where: ras_audits_bool_exp
    $status: String
    $offset: Int!
    $orderBy: [ras_audits_order_by!]
  ) {
    ras_audits_aggregate(
      where: {
        _and: [
          { status: { _eq: $status } }
          {
            _or: [
              { ras_id: { _eq: $exactQuery } }
              { company: { name: { _ilike: $query } } }
              { sales_office: { name: { _ilike: $query } } }
              { support_employee: { name: { _ilike: $query } } }
              { customer_name: { _ilike: $query } }
              { certificates: { standard: { _ilike: $query } } }
              { certificates: { number: { _ilike: $query } } }
              { certificates: { type: { _ilike: $query } } }
              { certificates: { audit_identifier: { _ilike: $query } } }
            ]
          }
          $where
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    ras_audits(
      order_by: $orderBy
      offset: $offset
      where: {
        _and: [
          { status: { _eq: $status } }
          {
            _or: [
              { ras_id: { _eq: $exactQuery } }
              { company: { name: { _ilike: $query } } }
              { sales_office: { name: { _ilike: $query } } }
              { support_employee: { name: { _ilike: $query } } }
              { customer_name: { _ilike: $query } }
              { certificates: { standard: { _ilike: $query } } }
              { certificates: { number: { _ilike: $query } } }
              { certificates: { type: { _ilike: $query } } }
              { certificates: { audit_identifier: { _ilike: $query } } }
            ]
          }
          $where
        ]
      }
      limit: 10
    ) {
      id
      acdc_id
      external_acdc_url
      internal_acdc_url
      ras_id
      combi_audit
      session_id
      company {
        id
        name
      }
      sales_office {
        id
        name
        code
      }
      support_employee {
        id
        name
        email
        initials
        hr_number
        profile_picture
      }
      customer_name
      customer_central_address
      certificates(order_by: { order: asc }) {
        id
        number
        standard
        type
        audit_identifier
      }
      start_date
      end_date
      status
    }
  }
`;

export const SEARCH_USERS_SIMPLE = gql`
  query SEARCH_USERS_SIMPLE(
    $query: String!
    $source: String
    $offset: Int!
    $orderBy: [ras_users_order_by!]
    $admin: String
    $role: String
  ) {
    ras_users_aggregate(
      where: {
        _and: [
          { source: { _eq: $source } }
          { admin: { _eq: $admin } }
          { admin_roles: { _has_key: $role } }
          {
            _or: [
              { name: { _ilike: $query } }
              { email: { _ilike: $query } }
              { hr_number: { _ilike: $query } }
              { customer_number: { _ilike: $query } }
              { location: { _ilike: $query } }
              { phone_number: { _ilike: $query } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    ras_users(
      limit: 10
      offset: $offset
      order_by: $orderBy
      where: {
        _and: [
          { source: { _eq: $source } }
          { admin: { _eq: $admin } }
          { admin_roles: { _has_key: $role } }
          {
            _or: [
              { name: { _ilike: $query } }
              { email: { _ilike: $query } }
              { hr_number: { _ilike: $query } }
              { customer_number: { _ilike: $query } }
              { location: { _ilike: $query } }
              { phone_number: { _ilike: $query } }
            ]
          }
        ]
      }
    ) {
      email
      id
      hr_number
      customer_number
      name
      oid
      role_id
      location
      time_zone
      phone_number
      qualifications
      profile_picture
      source
      initials
      active
      source_directory
      created_at
      updated_at
      admin
    }
  }
`;

export const DASHBOARD_AUDITS_QUERY = gql`
  query DASHBOARD_AUDITS_QUERY(
    $query: String!
    $status: String
    $offset: Int!
    $orderBy: [ras_audits_order_by!]
    $userId: bigint!
  ) {
    ras_audits(
      limit: 10
      offset: $offset
      order_by: $orderBy #{ created_at: desc }
      where: {
        participants: { user_id: { _eq: $userId } }
        status: { _neq: "STATUS_IN_DRAFT" }
        _and: [
          { status: { _eq: $status } }
          {
            _or: [
              { company: { name: { _ilike: $query } } }
              { sales_office: { name: { _ilike: $query } } }
              { support_employee: { name: { _ilike: $query } } }
              { customer_name: { _ilike: $query } }
              { certificates: { standard: { _ilike: $query } } }
              { certificates: { number: { _ilike: $query } } }
              { certificates: { type: { _ilike: $query } } }
              { certificates: { audit_identifier: { _ilike: $query } } }
            ]
          }
        ]
      }
    ) {
      id
      acdc_id
      internal_acdc_url
      external_acdc_url
      ras_id
      status
      audit_done
      start_date
      end_date
      customer_name
      session_id
      customer_central_address
      documents {
        author {
          name
          source
        }
        confidentiality
        file_name
        lifespan
        permissions
        created_at
      }
      participants {
        user_id
      }
      team_leader {
        id
        name
        email
        hr_number
        profile_picture
        initials
      }
      support_employee {
        id
        name
        hr_number
      }
      combi_audit
      certificates(order_by: { order: asc }) {
        id
        number
        standard
        type
        audit_identifier
      }
    }
    ras_audits_aggregate(
      where: {
        participants: { user_id: { _eq: $userId } }
        status: { _neq: "STATUS_IN_DRAFT" }
        _and: [
          { status: { _eq: $status } }
          {
            _or: [
              { company: { name: { _ilike: $query } } }
              { sales_office: { name: { _ilike: $query } } }
              { support_employee: { name: { _ilike: $query } } }
              { customer_name: { _ilike: $query } }
              { certificates: { standard: { _ilike: $query } } }
              { certificates: { number: { _ilike: $query } } }
              { certificates: { type: { _ilike: $query } } }
              { certificates: { audit_identifier: { _ilike: $query } } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TRANSLATIONS = gql`
  query GET_TRANSLATIONS {
    ras_translations {
      translationProfile
      isPublished
      code
      modifiedBy
      modifiedDate
      creationDate
    }
  }
`;
