import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router';
import NotFound from '../components/common/NotFound';
import Spinner from '../components/common/Spinner';
import { JOIN_QUERY } from './gql-model';

const Join = () => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const p = query.get('p');
  console.log(p);
  const { data, loading, error } = useQuery(JOIN_QUERY, {
    variables: { p },
    skip: !p,
  });

  if (loading) {
    return <Spinner position="absolute" />;
  }

  console.log(data);

  if (data.join.success) {
    history.replace(`/audit-session/${data.join.data}`);
  } else {
    return <NotFound />;
  }

  return <Spinner position="absolute" />;
};

export default Join;
