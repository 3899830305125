import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle as errorDefaultIcon,
  faHome as backToHomeIcon,
  faSyncAlt as refreshIcon,
} from '@fortawesome/pro-light-svg-icons';

const MSALErrorPage = ({ error }) => {
  const { t } = useTranslation();

  const getErrorPageRenderingValues = () => ({
    errorTitle: t('ERROR_PAGES.MSAL.TITLE'),
    errorIcon: errorDefaultIcon,
    errorMessage: t('ERROR_PAGES.MSAL.MESSAGE'),
  });
  const { errorTitle, errorIcon, errorMessage } = getErrorPageRenderingValues();

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="bg-white flex-1 items-center justify-center mt-28">
      <div className=" mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className=" text-5xl text-tuvr-dark-red ">
            <FontAwesomeIcon icon={errorIcon} /> {errorTitle}
          </h1>
          <p className=" mt-5 mx-auto text-xl text-gray-500">{errorMessage}</p>
          {error && (
            <p className=" mt-5 mx-auto text-xl text-gray-500">{error}</p>
          )}
          <p className="mt-5 mx-auto text-xl text-gray-500">
            <button
              type="button"
              className="text-tuvr-blue font-bold outline-none"
              onClick={() => (window.location = '/')}
            >
              <FontAwesomeIcon icon={backToHomeIcon} />{' '}
              {t('GENERIC.RELOAD_APP')}
            </button>
          </p>
          <p className=" mt-5 mx-auto text-xl text-gray-500">
            <button
              type="button"
              className="text-tuvr-blue font-bold outline-none"
              onClick={reloadPage}
            >
              <FontAwesomeIcon icon={refreshIcon} />
              {`${t('GENERIC.REFRESH_PAGE')}`}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

MSALErrorPage.propTypes = {
  error: PropTypes.any,
};

export default MSALErrorPage;
