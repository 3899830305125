import produce from 'immer';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import {
  AUDIT_ROLE,
  AUDIT_SESSION,
  AUDIT_SOURCE,
  MEETING_STATUS,
  NAVBAR_ITEMS,
} from '../../common/constants';
import {
  GET_SESSION_SIGNATURE_QUERY,
  CREATE_TEAMS_MEETING,
  END_MEETING_MUTATION,
  INSERT_AUDIT_REPORT_DATA,
} from '../../pages/gql-model';
import { toAuditMeetingTimeZone } from '../../utils/date-utils';

export const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};

export const JOIN_STATE = {
  NONE: 0,
  JOINING: 1,
  JOINED: 2,
};

export const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

export const isLeaderCheck = (participants, user) =>
  participants?.some(
    (p) =>
      p.user_id === user.id &&
      (p.roles.includes(AUDIT_ROLE.TEAM_LEADER) ||
        p.roles.includes(AUDIT_ROLE.TEAM_LEADER_DEPUTY))
  ) ?? false;

export const isTLeaderCheck = (participants, user) =>
  participants?.some(
    (p) => p.user_id === user.id && p.roles.includes(AUDIT_ROLE.TEAM_LEADER)
  ) ?? false;

export const getLeader = (participants) =>
  participants?.find((p) => p.roles.includes(AUDIT_ROLE.TEAM_LEADER)) ?? false;

export const getLeaderDeputy = (participants) =>
  participants?.filter((p) =>
    p.roles.includes(AUDIT_ROLE.TEAM_LEADER_DEPUTY)
  ) ?? false;

export const getAuditors = (participants) =>
  participants?.filter((p) => p.user.source === AUDIT_SOURCE.TUV) ?? false;

export const isSupportCheck = (participants, user) =>
  participants?.some(
    (p) => p.user_id === user.id && p.roles.includes(AUDIT_ROLE.SUPPORT)
  ) ?? false;

export const userAsParticipant = (participants, user) =>
  participants.find((p) => p.user_id === user.id);

export const joinSessionTest = async (
  user,
  meeting,
  client,
  setCurrentMeeting,
  setJoinState,
  setMediaStream,
  zoomClient,
  setIsLoading,
  setSingleVideoView,
  setErr
) => {
  try {
    setCurrentMeeting(meeting);
    setJoinState(JOIN_STATE.JOINING);
    const userName = user.name;
    const signatureResponse = await client.query({
      query: GET_SESSION_SIGNATURE_QUERY,
      variables: {
        topic: meeting.meeting_id,
        password: meeting.meeting_access_code.substring(0, 8),
      },
    });
    const signature = signatureResponse.data.get_session_signature;
    await zoomClient.join(
      meeting.meeting_id,
      signature,
      userName,
      meeting.meeting_access_code.substring(0, 8)
    );
    const stream = zoomClient.getMediaStream();
    if (zoomClient.isHost()) {
      stream.lockShare(false);
    }
    setMediaStream(stream);
    setSingleVideoView(!stream.isSupportMultipleVideos());
    setJoinState(JOIN_STATE.JOINED);
  } catch (err) {
    setErr(err);
    console.log(err);
    setJoinState(JOIN_STATE.NONE);
  } finally {
    setIsLoading(false);
  }
};

export const handleCreateTeamsMeeting = async (
  client,
  audit,
  meeting,
  refetch = false
) => {
  const ogDate = meeting.originalDate || meeting.date;
  const teamsValues = toAuditMeetingTimeZone(audit, {
    date: ogDate,
    start_time: `${meeting.start_time.split(':')[0].padStart(2, '0')}:${
      meeting.start_time.split(':')[1]
    }:00`,
    end_time: `${meeting.end_time.split(':')[0].padStart(2, '0')}:${
      meeting.end_time.split(':')[1]
    }:00`,
  });
  await client.mutate({
    mutation: CREATE_TEAMS_MEETING,
    variables: {
      id: meeting.id,
      endDateTime: dayjs(`${ogDate} ${teamsValues.end_time}`),
      startDateTime: dayjs(`${ogDate} ${teamsValues.start_time}`),
      title: meeting.title,
    },
  });
};
export const isMicrosoftTeamsSetAsDefault = (meeting) =>
  meeting?.teams_as_default_conference && meeting?.teams_meeting?.joinUrl;

export const openMicrosoftTeamsMeeting = (meeting) => {
  // navigate to join url
  const strWindowFeatures =
    'location=yes,height=570,width=520,scrollbars=yes,status=yes';
  window.open(meeting.teams_meeting.joinUrl, '_blank', strWindowFeatures);
};

export const joinSession = async (
  user,
  meeting,
  client,
  setCurrentMeeting,
  setJoinState,
  setMediaStream,
  zoomClient,
  setIsLoading,
  setSingleVideoView,
  setErr,
  publishOnlineStatus,
  auditId,
  defaultMeeting,
  isAuditor
) => {
  try {
    setJoinState(JOIN_STATE.JOINING);
    const userName = user.name;
    setCurrentMeeting(meeting.lane_id === 0 ? defaultMeeting : meeting);
    if (isMicrosoftTeamsSetAsDefault(meeting)) {
      // navigate to join url
      openMicrosoftTeamsMeeting(meeting);
    } else {
      const [topic, password, mTitle] =
        meeting.lane_id === 0
          ? [
              defaultMeeting.meeting_id,
              defaultMeeting.meeting_access_code.substring(0, 8),
              defaultMeeting.title,
            ]
          : [
              meeting.meeting_id,
              meeting.meeting_access_code.substring(0, 8),
              meeting.title,
            ];
      const role = isAuditor || meeting.lane_id === 0 ? 1 : 0;
      const signatureResponse = await client.query({
        query: GET_SESSION_SIGNATURE_QUERY,
        variables: {
          topic,
          password,
          role,
        },
      });
      const signature = signatureResponse.data.get_session_signature;
      const resp = await zoomClient.join(topic, signature, userName, password);
      const currentUserInfo = zoomClient.getCurrentUserInfo();
      const isUserJoined = zoomClient.getUser(currentUserInfo?.userId);
      if (currentUserInfo && resp && isUserJoined) {
        publishOnlineStatus(AUDIT_SESSION.ONLINE_STATUS.IS_MOBILE_ON_JOIN, {
          auditId,
          meetingId: topic,
          ...currentUserInfo,
          isMobile,
          meetingTitle: mTitle,
          rasUserId: user.id,
        });
        publishOnlineStatus(AUDIT_SESSION.ONLINE_STATUS.IS_MOBILE_REQUEST, {
          userId: currentUserInfo.userId,
        });
      }
      const stream = zoomClient.getMediaStream();
      if (zoomClient.isHost()) {
        try {
          stream.lockShare(false);
        } catch (err) {
          console.log(err);
        }
      }
      setMediaStream(stream);
      setSingleVideoView(!stream.isSupportMultipleVideos());
    }
    setJoinState(JOIN_STATE.JOINED);
    client.mutate({
      mutation: INSERT_AUDIT_REPORT_DATA,
      variables: { auditId },
    });
  } catch (err) {
    setErr(err);
    console.log(err);
    // console.error(err.reason);
    setMediaStream(null);
    setCurrentMeeting(null);
    setJoinState(JOIN_STATE.NONE);
  } finally {
    setIsLoading(false);
  }
};

export const meetingEnd = async (
  meeting,
  client,
  zmClient,
  setMediaStream,
  setJoinState,
  setSingleVideoView,
  setCurrentMeeting,
  publishRefetchNotification,
  type,
  currentMeeting,
  publishOnlineStatus,
  auditId
) => {
  const { data } = await client.mutate({
    mutation: END_MEETING_MUTATION,
    variables: { meetingId: meeting.id, endedAt: new Date() },
  });

  try {
    if (meeting === currentMeeting) {
      const currentUserInfo = zmClient.getCurrentUserInfo();
      const resp = await zmClient.leave(zmClient.isHost());
      if (currentUserInfo && resp === '') {
        publishOnlineStatus(
          zmClient.isHost()
            ? AUDIT_SESSION.ONLINE_STATUS.IS_MOBILE_ON_END
            : AUDIT_SESSION.ONLINE_STATUS.IS_MOBILE_ON_LEAVE,
          {
            auditId,
            meetingId: meeting.meeting_id,
            userId: currentUserInfo.userId,
          }
        );
      }
      setMediaStream(null);
      setJoinState(JOIN_STATE.NONE);
      setCurrentMeeting(null);
    }
  } catch (err) {
    console.warn(err);
  }
  setSingleVideoView(null);
  publishRefetchNotification(type, data.update_ras_audit_meetings_by_pk);
};

export const handleRejoin = async (
  handleMeetingJoin,
  LOCAL_STORAGE_KEY,
  meetings
) => {
  try {
    const meeting = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEY));
    if (
      meeting &&
      meetings?.find((e) => e.id === meeting?.id)?.status !==
        MEETING_STATUS.ENDED
    ) {
      handleMeetingJoin(meeting);
      sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleExitCurrentAuditMeeting = (
  currentMeeting,
  meetings,
  handleExitCurrentSessionMeeting
) => {
  try {
    if (
      meetings?.find((e) => e.id === currentMeeting?.id)?.status ===
      MEETING_STATUS.ENDED
    ) {
      handleExitCurrentSessionMeeting();
    }
  } catch (error) {
    console.log(error);
  }
};
export const handleExitMeeting = async (
  setJoinState,
  setCurrentMeeting,
  zoomClient,
  setMediaStream,
  currentMeeting,
  publishOnlineStatus,
  auditId,
  removeZoomParticipant,
  isMeetingApproved,
  setIsMeetingApproved
) => {
  if (!isMicrosoftTeamsSetAsDefault(currentMeeting)) {
    try {
      const currentUserInfo = zoomClient.getCurrentUserInfo();
      removeZoomParticipant({
        auditId,
        meetingId: currentMeeting.meeting_id,
        userId: currentUserInfo?.userId,
      });
      const resp = await zoomClient.leave();
      const isUserLeft = !zoomClient.getUser(currentUserInfo?.userId);
      if (currentUserInfo && resp === '' && isUserLeft) {
        publishOnlineStatus(AUDIT_SESSION.ONLINE_STATUS.IS_MOBILE_ON_LEAVE, {
          auditId,
          meetingId: currentMeeting.meeting_id,
          userId: currentUserInfo?.userId,
        });
      }
      setMediaStream(null);
      setJoinState(JOIN_STATE.NONE);
      setCurrentMeeting(null);
      if (isMeetingApproved) {
        setIsMeetingApproved(false);
      }
    } catch (err) {
      console.warn(err);
    }
  } else {
    setJoinState(JOIN_STATE.NONE);
    setCurrentMeeting(null);
    if (isMeetingApproved) {
      setIsMeetingApproved(false);
    }
  }
};
export const handleNavbarToggle = (item, setActiveNavbarTab) => {
  switch (item) {
    case NAVBAR_ITEMS.CHAT:
    case NAVBAR_ITEMS.FILES:
    case NAVBAR_ITEMS.HANDRAISED:
    case NAVBAR_ITEMS.PARTICIPANTS:
    case NAVBAR_ITEMS.BACKGROUND_IMAGES:
    case NAVBAR_ITEMS.TOOLS:
      setActiveNavbarTab(item);
      break;
    default:
  }
};
