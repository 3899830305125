import {
  GET_AUDIT_LIST_PAGE_SUCCESS,
  TOGGLE_AUDIT_LIST_ADVANCED_FILTER,
  SET_AUDIT_LIST_QUERY,
  SET_AUDIT_LIST_STATUS,
  SET_ADVANCED_FILTER,
  SET_AUDIT_LIST_PAGE,
  SET_AUDIT_LIST_EXPANSION_STATE,
  SET_AUDIT_LIST_SORT,
  RESET_ADVANCED_FILTER,
} from '../constants';

/* eslint-disable import/prefer-default-export */
const initialState = {
  advancedFilterOpen: false,
  data: [],
  query: '',
  status: undefined,
  advancedFilter: {},
  total: 0,
  page: 1,
  expansionState: {},
  sortDir: -1,
  sortProp: 'id',
};

const nextSort = (currentSortProp, selectedSortProp, currentSortDir) => {
  if (currentSortProp === selectedSortProp && currentSortDir === -1) {
    return {
      sortProp: undefined,
      sortDir: undefined,
    };
  }

  if (currentSortProp === selectedSortProp && currentSortDir === 1) {
    return {
      sortProp: selectedSortProp,
      sortDir: -1,
    };
  }

  return {
    sortProp: selectedSortProp,
    sortDir: 1,
  };
};

const auditListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AUDIT_LIST_ADVANCED_FILTER: {
      return {
        ...state,
        advancedFilterOpen: !state.advancedFilterOpen,
      };
    }
    case GET_AUDIT_LIST_PAGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    }
    case SET_AUDIT_LIST_QUERY: {
      return {
        ...state,
        query: action.payload,
        page: 1,
      };
    }
    case SET_AUDIT_LIST_STATUS: {
      return {
        ...state,
        status: action.payload === 0 ? undefined : action.payload,
        page: 1,
      };
    }
    case SET_ADVANCED_FILTER: {
      return {
        ...state,
        advancedFilter: action.payload,
        page: 1,
      };
    }
    case RESET_ADVANCED_FILTER: {
      return {
        ...state,
        query: '',
        status: undefined,
        advancedFilter: {},
        page: 1,
      };
    }
    case SET_AUDIT_LIST_PAGE: {
      return {
        ...state,
        page: action.payload,
        expansionState: {},
      };
    }
    case SET_AUDIT_LIST_EXPANSION_STATE: {
      return {
        ...state,
        expansionState: action.payload,
      };
    }
    case SET_AUDIT_LIST_SORT: {
      const nextSorting = nextSort(
        state.sortProp,
        action.payload,
        state.sortDir
      );
      return {
        ...state,
        ...nextSorting,
      };
    }
    default:
      return state;
  }
};

export default auditListReducer;
