import {
  GET_AUDIT_LIST_PAGE,
  TOGGLE_AUDIT_LIST_ADVANCED_FILTER,
  GET_AUDIT_LIST_PAGE_SUCCESS,
  GET_AUDIT_LIST_PAGE_ERROR,
  SET_AUDIT_LIST_QUERY,
  SET_AUDIT_LIST_STATUS,
  SET_ADVANCED_FILTER,
  SET_AUDIT_LIST_PAGE,
  SET_AUDIT_LIST_EXPANSION_STATE,
  SET_AUDIT_LIST_SORT,
  GET_USERS_LIST_PAGE,
  GET_USERS_LIST_PAGE_SUCCESS,
  GET_USERS_LIST_PAGE_ERROR,
  SET_USERS_LIST_QUERY,
  SET_USERS_LIST_STATUS,
  SET_USERS_LIST_PAGE,
  SET_USERS_LIST_SORT,
  GET_DASHBOARD_AUDIT_LIST_PAGE,
  GET_DASHBOARD_AUDIT_LIST_PAGE_SUCCESS,
  GET_DASHBOARD_AUDIT_LIST_PAGE_ERROR,
  SET_DASHBOARD_AUDIT_LIST_QUERY,
  SET_DASHBOARD_AUDIT_LIST_STATUS,
  SET_DASHBOARD_AUDIT_LIST_PAGE,
  SET_DASHBOARD_AUDIT_LIST_SORT,
  SET_DASHBOARD_AUDIT_LIST_USER_ID,
  SET_DASHBOARD_AUDIT_LIST_EXPANSION_STATE,
  RESET_ADVANCED_FILTER,
} from './constants';

export const getAuditListPage = () => ({
  type: GET_AUDIT_LIST_PAGE,
});

export const getAuditListPageSuccess = (data, total) => ({
  type: GET_AUDIT_LIST_PAGE_SUCCESS,
  payload: { data, total },
});

export const getAuditListPageError = () => ({
  type: GET_AUDIT_LIST_PAGE_ERROR,
});

export const toggleAuditListAdvancedFilter = () => ({
  type: TOGGLE_AUDIT_LIST_ADVANCED_FILTER,
});

export const setAuditListQuery = (query) => ({
  type: SET_AUDIT_LIST_QUERY,
  payload: query,
});

export const setAuditListStatus = (statusId) => ({
  type: SET_AUDIT_LIST_STATUS,
  payload: statusId,
});

export const setAdvancedFilter = (filter) => ({
  type: SET_ADVANCED_FILTER,
  payload: filter,
});

export const resetAdvancedFilter = () => ({
  type: RESET_ADVANCED_FILTER,
});

export const setAuditListPage = (page) => ({
  type: SET_AUDIT_LIST_PAGE,
  payload: page,
});

export const setAuditListExpansionState = (expansionState) => ({
  type: SET_AUDIT_LIST_EXPANSION_STATE,
  payload: expansionState,
});

export const setAuditListSort = (prop) => ({
  type: SET_AUDIT_LIST_SORT,
  payload: prop,
});

export const getUsersListPage = () => ({
  type: GET_USERS_LIST_PAGE,
});

export const getUsersListPageSuccess = (data, total) => ({
  type: GET_USERS_LIST_PAGE_SUCCESS,
  payload: { data, total },
});

export const getUsersListPageError = () => ({
  type: GET_USERS_LIST_PAGE_ERROR,
});

export const setUsersListQuery = (query) => ({
  type: SET_USERS_LIST_QUERY,
  payload: query,
});

export const setUsersListStatus = (statusId) => ({
  type: SET_USERS_LIST_STATUS,
  payload: statusId,
});

export const setUsersListPage = (page) => ({
  type: SET_USERS_LIST_PAGE,
  payload: page,
});

export const setUsersListSort = (prop) => ({
  type: SET_USERS_LIST_SORT,
  payload: prop,
});

export const getDashboardAuditsListPage = () => ({
  type: GET_DASHBOARD_AUDIT_LIST_PAGE,
});

export const getDashboardAuditsListPageSuccess = (data, total) => ({
  type: GET_DASHBOARD_AUDIT_LIST_PAGE_SUCCESS,
  payload: { data, total },
});

export const getDashboardAuditsListPageError = () => ({
  type: GET_DASHBOARD_AUDIT_LIST_PAGE_ERROR,
});

export const setDashboardAuditsListQuery = (query) => ({
  type: SET_DASHBOARD_AUDIT_LIST_QUERY,
  payload: query,
});

export const setDashboardAuditsListStatus = (statusId) => ({
  type: SET_DASHBOARD_AUDIT_LIST_STATUS,
  payload: statusId,
});

export const setDashboardAuditsListUserId = (userId) => ({
  type: SET_DASHBOARD_AUDIT_LIST_USER_ID,
  payload: userId,
});

export const setDashboardAuditsListPage = (page) => ({
  type: SET_DASHBOARD_AUDIT_LIST_PAGE,
  payload: page,
});

export const setDashboardAuditsListExpansionState = (expansionState) => ({
  type: SET_DASHBOARD_AUDIT_LIST_EXPANSION_STATE,
  payload: expansionState,
});

export const setDashboardAuditsListSort = (prop) => ({
  type: SET_DASHBOARD_AUDIT_LIST_SORT,
  payload: prop,
});
