import {
  GET_USERS_LIST_PAGE_SUCCESS,
  SET_USERS_LIST_QUERY,
  SET_USERS_LIST_STATUS,
  SET_USERS_LIST_PAGE,
  SET_USERS_LIST_SORT,
} from '../constants';
import { SYSTEM_ACCOUNT_TASKS, SYSTEM_ACCOUNT_TYPE } from '../common/constants';

/* eslint-disable import/prefer-default-export */
const initialState = {
  data: [],
  query: '',
  status: undefined,
  total: 0,
  page: 1,
  sortDir: -1,
  sortProp: 'id',
};

const nextSort = (currentSortProp, selectedSortProp, currentSortDir) => {
  if (currentSortProp === selectedSortProp && currentSortDir === -1) {
    return {
      sortProp: undefined,
      sortDir: undefined,
    };
  }

  if (currentSortProp === selectedSortProp && currentSortDir === 1) {
    return {
      sortProp: selectedSortProp,
      sortDir: -1,
    };
  }

  return {
    sortProp: selectedSortProp,
    sortDir: 1,
  };
};

const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_LIST_PAGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    }
    case SET_USERS_LIST_QUERY: {
      return {
        ...state,
        query: action.payload,
        page: 1,
      };
    }
    case SET_USERS_LIST_STATUS: {
      return {
        ...state,
        status: action.payload === 'ALL' ? undefined : action.payload,
        source:
          action.payload === 'ALL' ||
          action.payload === SYSTEM_ACCOUNT_TYPE.ADMIN ||
          action.payload === SYSTEM_ACCOUNT_TASKS.LANGUAGE_TASK ||
          action.payload === SYSTEM_ACCOUNT_TASKS.BACKGROUND_TASK
            ? undefined
            : action.payload,
        admin:
          action.payload === SYSTEM_ACCOUNT_TYPE.ADMIN
            ? SYSTEM_ACCOUNT_TYPE.ADMIN
            : undefined,
        role:
          action.payload === SYSTEM_ACCOUNT_TASKS.LANGUAGE_TASK ||
          action.payload === SYSTEM_ACCOUNT_TASKS.BACKGROUND_TASK
            ? action.payload
            : undefined,
        page: 1,
      };
    }
    case SET_USERS_LIST_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case SET_USERS_LIST_SORT: {
      const nextSorting = nextSort(
        state.sortProp,
        action.payload,
        state.sortDir
      );
      return {
        ...state,
        ...nextSorting,
      };
    }
    default:
      return state;
  }
};

export default usersListReducer;
