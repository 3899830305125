import { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../../context/UserContextProvider';

const RestrictedContent = ({ roles, children }) => {
  const { user } = useContext(UserContext);
  if (user) {
    if (roles) {
      return roles.includes(user.role.key) ? children : null;
    }

    return children;
  }

  return null;
};

RestrictedContent.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RestrictedContent;
