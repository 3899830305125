import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezones from 'timezones-list';

const customParseFormat = require('dayjs/plugin/customParseFormat');
const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc');
const minMax = require('dayjs/plugin/minMax');

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(objectSupport);
dayjs.extend(minMax);

export const getSessionStartDate = (audit) => {
  if (!audit.meetings || audit.meetings.length === 0 || !audit.timezone) {
    return undefined;
  }

  const [firstMeeting] = audit.meetings;
  const firstMeetingDate = dayjs.tz(
    `${firstMeeting.date} ${firstMeeting.start_time}`,
    'YYYY-MM-DD HH:mm:ss',
    audit.timezone
  );
  return firstMeetingDate.tz(dayjs.tz.guess());
};

export const getSessionEndDate = (audit) => {
  if (!audit.meetings || audit.meetings.length === 0 || !audit.timezone) {
    return undefined;
  }

  const lastMeeting = audit.meetings[audit.meetings.length - 1];
  const lastMeetingDate = dayjs.tz(
    `${lastMeeting.date} ${lastMeeting.end_time}`,
    'YYYY-MM-DD HH:mm:ss',
    audit.timezone
  );
  return lastMeetingDate.tz(dayjs.tz.guess());
};

export const toLocalMeeting = (timeZone, userTimeZone, meeting) => {
  const toTimeZone = userTimeZone || dayjs.tz.guess();
  const meetingStartDate = dayjs
    .tz(
      `${meeting.date} ${meeting.start_time}`,
      'YYYY-MM-DD HH:mm:ss',
      timeZone
    )
    .tz(toTimeZone);
  const meetingEndDate = dayjs
    .tz(`${meeting.date} ${meeting.end_time}`, 'YYYY-MM-DD HH:mm:ss', timeZone)
    .tz(toTimeZone);

  return {
    ...meeting,
    date: meetingStartDate.format('YYYY-MM-DD'),
    start_time: meetingStartDate.format('HH:mm'),
    end_time: meetingEndDate.format('HH:mm'),
  };
};

export const localizeDateValue = (timeZone, datestring) =>
  dayjs
    .tz(`${datestring}`, 'YYYY-MM-DD HH:mm:ss', timeZone)
    .tz(dayjs.tz.guess());

export const localizeAudit = (audit) => ({
  ...audit,
  start_date: getSessionStartDate(audit),
  end_date: getSessionEndDate(audit),
  meetings: (audit.meetings || []).map((meeting) =>
    toLocalMeeting(audit.timezone, meeting)
  ),
});

export const toAuditMeetingTimeZone = (audit, meeting) => {
  const [meetingStartDate, meetingEndDate] =
    audit && audit.timezone
      ? [
          dayjs(`${meeting.date} ${meeting.start_time}`).tz(audit.timezone),
          dayjs(`${meeting.date} ${meeting.end_time}`).tz(audit.timezone),
        ]
      : [
          dayjs(`${meeting.date} ${meeting.start_time}`),
          dayjs(`${meeting.date} ${meeting.end_time}`),
        ];
  return {
    ...meeting,
    date: meetingStartDate.format('YYYY-MM-DD'),
    start_time: meetingStartDate.format('HH:mm'),
    end_time: meetingEndDate.format('HH:mm'),
  };
};

export const getLocalTimeZone = (user) => user?.time_zone || dayjs.tz.guess();

const equivalentHours = {
  0: 24,
  1: 25,
  2: 26,
  3: 27,
  4: 28,
  5: 29,
  6: 30,
  7: 31,
  8: 32,
  9: 33,
  10: 34,
  11: 35,
  12: 36,
};

export const getTimeZoneOffset = (audit) => {
  const isAcdcAudit = !!audit?.acdc_id;
  if (isAcdcAudit) {
    const selectedOption = timezones.find(
      (tz) => tz.tzCode === audit?.timezone
    );
    return `UTC ${selectedOption?.utc}`;
  }
  return audit?.timezone;
};

export const getDurationInHours = (startTime, endTime) => {
  let startTimeHour = Number(startTime.split(':')[0]);
  const startTimeMinutes = Number(startTime.split(':')[1]);
  let endTimeHour = Number(endTime.split(':')[0]);
  const endTimeMinutes = Number(endTime.split(':')[1]);

  if (endTimeHour < startTimeHour) {
    endTimeHour = equivalentHours[endTimeHour];
  }

  if (startTimeMinutes > 0 && startTimeMinutes <= 15) {
    startTimeHour += 0.25;
  } else if (startTimeMinutes > 15 && startTimeMinutes <= 30) {
    startTimeHour += 0.5;
  } else if (startTimeMinutes > 30 && startTimeMinutes <= 45) {
    startTimeHour += 0.75;
  } else if (startTimeMinutes > 45) {
    startTimeHour += 1;
  }

  if (endTimeMinutes > 0 && endTimeMinutes <= 15) {
    endTimeHour += 0.25;
  } else if (endTimeMinutes > 15 && endTimeMinutes <= 30) {
    endTimeHour += 0.5;
  } else if (endTimeMinutes > 30 && endTimeMinutes <= 45) {
    endTimeHour += 0.75;
  } else if (endTimeMinutes > 45) {
    endTimeHour += 1;
  }

  let length = endTimeHour - startTimeHour;

  if (endTimeHour >= 24) {
    length -= endTimeHour - 24;
    return { length, isNextDay: true };
  }

  return { length, isNextDay: false };
};

export const getMaxTime = (meetings, defaultMeeting) => {
  let max;
  meetings.forEach((meeting) => {
    const start = defaultMeeting ? meeting.startTime : meeting.start_time;
    const end = defaultMeeting ? meeting.endTime : meeting.end_time;
    const midnight = dayjs({ hour: 24, minute: 0 });
    const startTimeHour = Number(start.split(':')[0]);
    const endTimeHour = Number(end.split(':')[0]);
    const endTimeMinutes = Number(end.split(':')[1]);
    const endTime = dayjs({ hour: endTimeHour, minute: endTimeMinutes });
    const length = Math.abs(endTimeHour - startTimeHour);
    if (!max) {
      max = endTime;
      return;
    }
    if (max === midnight) {
      return;
    }
    if (startTimeHour + length >= 24) {
      max = midnight;
      return;
    }
    if (dayjs(endTime).isAfter(max)) {
      max = endTime;
    }
  });
  return max.format('HH:mm');
};

export const getMinTime = (hours) => {
  const min = dayjs.min(
    hours.map((date) => {
      const hour = Number(date.split(':')[0]);
      const minute = Number(date.split(':')[1]);
      return dayjs({ hour, minute });
    })
  );
  return min.format('HH:mm');
};

export const orderHours = (a, b) => {
  const hourA = Number(a.startTime.split(':')[0]);
  const hourB = Number(b.startTime.split(':')[0]);
  const minutesA = Number(a.startTime.split(':')[1]);
  const minutesB = Number(b.startTime.split(':')[1]);
  return hourA !== hourB ? hourA - hourB : minutesA - minutesB;
};

const getEventTime = (meetingData) => {
  const { start, end } = meetingData;
  const eventStart =
    typeof start === 'object' ? dayjs(start).format('HH:mm') : start;
  const eventEnd = typeof end === 'object' ? dayjs(end).format('HH:mm') : end;
  const eventStartHour = Number(eventStart?.split(':')[0]);
  const eventStartMinutes = Number(eventStart?.split(':')[1]);
  const eventStartTime = eventStartHour + eventStartMinutes / 60;
  const eventEndHour = Number(eventEnd?.split(':')[0]);
  const eventEndMinutes = Number(eventEnd?.split(':')[1]);
  const eventEndTime = eventEndHour + eventEndMinutes / 60;
  return { eventStartTime, eventEndTime };
};

export const isOverlapping = (eventStart, eventEnd, start, end) => {
  const { eventStartTime, eventEndTime } = getEventTime({
    start: eventStart,
    end: eventEnd,
  });

  const startTime = getEventTime({ start, end }).eventStartTime;
  const endTime = getEventTime({ start, end }).eventEndTime;

  if (eventStartTime <= startTime && eventEndTime > startTime) {
    return true;
  }
  if (eventStartTime >= startTime && eventStartTime < endTime) {
    return true;
  }
};

export const getTimeObject = (time) => {
  const hour = Number(time.split(':')[0]);
  const minute = Number(time.split(':')[1]);
  return dayjs({ hour, minute });
};

export const checkMinMeetingDuration = (meetingData) => {
  const { eventStartTime, eventEndTime } = getEventTime(meetingData);
  return Math.abs(eventEndTime - eventStartTime) < 0.25;
};

export const checkMeetingEndsAfterStart = (meetingData) => {
  const { eventStartTime, eventEndTime } = getEventTime(meetingData);
  return eventEndTime < eventStartTime;
};

export const isOlderThan7CalendarDays = (meetingEndDate) =>
  dayjs(meetingEndDate).diff(new Date(), 'day') >= -7;
