import { takeEvery, call, put, select } from 'redux-saga/effects';
import set from 'lodash.set';
import { getDashboardAuditsListPageSuccess } from '../actions';
import { fetchDashboardAuditsPage } from '../api/api';
import {
  GET_DASHBOARD_AUDIT_LIST_PAGE,
  SET_DASHBOARD_AUDIT_LIST_PAGE,
  SET_DASHBOARD_AUDIT_LIST_QUERY,
  SET_DASHBOARD_AUDIT_LIST_SORT,
  SET_DASHBOARD_AUDIT_LIST_STATUS,
  SET_DASHBOARD_AUDIT_LIST_USER_ID,
} from '../constants';

const querySelector = (state) => state.dashboardAuditsList.query;
const statusSelector = (state) => state.dashboardAuditsList.status;
const pageSelector = (state) => state.dashboardAuditsList.page;
const sortDirSelector = (state) => state.dashboardAuditsList.sortDir;
const sortPropSelector = (state) => state.dashboardAuditsList.sortProp;
const userIdSelector = (state) => state.dashboardAuditsList.userId;

const getOrderBy = (sortProp, sortDir) =>
  set({}, sortProp, sortDir === 1 ? 'asc' : 'desc');

export function* handleDashboardAuditsListPageSaga() {
  try {
    const query = yield select(querySelector);
    const status = yield select(statusSelector);
    const page = yield select(pageSelector);
    const sortDir = yield select(sortDirSelector);
    const sortProp = yield select(sortPropSelector);
    const userId = yield select(userIdSelector);
    const offset = (page - 1) * 10;
    const response = yield call(
      fetchDashboardAuditsPage,
      query,
      status,
      offset,
      sortProp ? getOrderBy(sortProp, sortDir) : undefined,
      userId
    );
    yield put(
      getDashboardAuditsListPageSuccess(
        response.data.ras_audits,
        response.data.ras_audits_aggregate.aggregate.count
      )
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call(console.error, error);
  }
}

export function* dashboardAuditsListPageSaga() {
  yield takeEvery(
    [
      GET_DASHBOARD_AUDIT_LIST_PAGE,
      SET_DASHBOARD_AUDIT_LIST_QUERY,
      SET_DASHBOARD_AUDIT_LIST_STATUS,
      SET_DASHBOARD_AUDIT_LIST_PAGE,
      SET_DASHBOARD_AUDIT_LIST_SORT,
      SET_DASHBOARD_AUDIT_LIST_USER_ID,
    ],
    handleDashboardAuditsListPageSaga
  );
}
