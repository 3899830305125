import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AuditInterrupted = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white flex-1 items-center justify-center mt-28">
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="flex flex-col gap-6 text-center">
          <div className="mt-1 text-4xl font-extrabold text-tuvr-gray sm:text-5xl sm:tracking-tight lg:text-6xl">
            {t('MESSAGES.AUDIT_INTERRUPTED.DESCRIPTION')}
          </div>
          <div className="mx-auto text-xl text-gray-500">
            <Link to="/">
              <button
                type="button"
                className="text-tuvr-blue font-bold text-2xl"
              >
                {t('GENERIC.BACK')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditInterrupted;
