import React, { useContext, useState, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import Header from './header/Header';
import Sidebar from './Sidebar';
import NotFound from './common/NotFound';
import Snackbar from './core/Snackbar';
import SnackbarLongText from './core/SnackbarLongText';
import { SYSTEM_ROLE, SYSTEM_ACCOUNT_TASKS } from '../common/constants';
import { UserContext } from '../context/UserContextProvider';
import Join from '../pages/Join';
import { BroswerDetect } from './BroswerDetect';
import { AgendaContext } from '../context/AgendaContext';

const CreateNewAudit = React.lazy(() => import('../pages/CreateNewAudit'));
const UsersAdministrationList = React.lazy(() =>
  import('../pages/UsersAdministrationList')
);
const EditAudit = React.lazy(() => import('../pages/EditAudit'));
const UserProfile = React.lazy(() => import('../pages/UserProfile'));
const AuditsList = React.lazy(() => import('../pages/AuditsList'));
const AuditSessionPage = React.lazy(() => import('../pages/AuditSessionPage'));
const About = React.lazy(() => import('../pages/About'));
const TrainingCenter = React.lazy(() => import('../pages/TrainingCenter'));
const EditUser = React.lazy(() => import('../pages/EditUser'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Translation = React.lazy(() => import('../pages/Translation'));
const EditLanguage = React.lazy(() => import('./Translation/EditLanguage'));

const Router = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isLanguage =
    user?.admin_roles?.some((p) => p === SYSTEM_ACCOUNT_TASKS.LANGUAGE_TASK) ??
    false;
  const isGuest = user?.role?.key === SYSTEM_ROLE.GUEST;
  const isRoot = user?.role?.key === SYSTEM_ROLE.ROOT;
  const time = new Date();
  const [activatedCurrentIcon, setActivatedCurrentIcon] = useState('/');
  const { currentUserOnline } = useContext(AgendaContext);

  return (
    <Suspense fallback={<div>Loading ... </div>}>
      <BrowserRouter>
        <Switch>
          {isMobile && (
            <Route
              path="/audit-session/:sessionId/"
              exact
              render={(props) => <AuditSessionPage mobile />}
            />
          )}
          <Route
            path="/audit-session/:sessionId/mobile"
            exact
            render={(props) => <AuditSessionPage mobile />}
          />
          <Route
            render={() => (
              <div className="bg-white flex flex-wrap flex-col">
                <Header appTitle={`${t('APP_TITLE')}`} />
                <BroswerDetect />
                <div
                  className="flex flex-1 flex-row"
                  style={{ minHeight: 'calc(100vh - 60px)' }}
                >
                  <Sidebar
                    activatedCurrentIcon={activatedCurrentIcon}
                    setActivatedCurrentIcon={setActivatedCurrentIcon}
                    isDisabled={currentUserOnline}
                  />
                  <Switch>
                    <Route path="/join" exact component={Join} />
                    <Route
                      path="/"
                      exact
                      component={() => (
                        <Dashboard
                          t={t}
                          account={user}
                          time={time}
                          setActivatedCurrentIcon={setActivatedCurrentIcon}
                        />
                      )}
                    />
                    {isRoot && (
                      <Route path="/audits" exact component={AuditsList} />
                    )}
                    {!isGuest && (
                      <Route path="/audits/:id" exact component={EditAudit} />
                    )}
                    <Route
                      path="/training-center"
                      exact
                      component={TrainingCenter}
                    />
                    {isRoot && (
                      <Route
                        path="/admin"
                        exact
                        component={UsersAdministrationList}
                      />
                    )}
                    {isRoot && (
                      <Route
                        path="/new-audit"
                        exact
                        component={CreateNewAudit}
                      />
                    )}
                    <Route
                      path="/audit-session/:sessionId"
                      exact
                      render={(props) => <AuditSessionPage mobile={false} />}
                    />
                    {isRoot && isLanguage && (
                      <Route
                        path="/translation"
                        exact
                        component={Translation}
                      />
                    )}
                    {isRoot && isLanguage && (
                      <Route
                        path="/editLanguage"
                        exact
                        component={EditLanguage}
                      />
                    )}
                    <Route path="/profile" exact component={UserProfile} />
                    {isRoot && (
                      <Route path="/user/:userId" exact component={EditUser} />
                    )}
                    <Route path="/about" exact component={About} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            )}
          />
        </Switch>

        <Snackbar />
        <SnackbarLongText />
      </BrowserRouter>
    </Suspense>
  );
};

Router.propTypes = {};

export default Router;
