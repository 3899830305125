import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { nanoid } from 'nanoid';
import {
  AUDIT_ROLE,
  AUDIT_FIELD_FORMAT_VALUES,
  TRANSLATION_CATEGORY,
  AUDIT_TYPES,
} from './constants';

dayjs.extend(localizedFormat);

const { DATE_FORMAT_DE, DATE_FORMAT_EN, DATE_FORMAT_DEFAULT } =
  AUDIT_FIELD_FORMAT_VALUES;

export const dateTransformer = (usr) => {
  if (usr?.locale !== 'default') {
    return usr?.locale.substr(0, 2) === 'de' ? DATE_FORMAT_DE : DATE_FORMAT_EN;
  }
  return DATE_FORMAT_DEFAULT;
};

export const dateLowerCase = (usr) => dateTransformer(usr).toLowerCase();

export const localeDate = (stringValue, usr) => {
  if (usr?.locale !== 'default')
    return stringValue ? dayjs(stringValue).format('L') : '';
  return stringValue ? dayjs(stringValue).format('YYYY-MM-DD') : '';
};

export const localeTime = (stringValue) =>
  stringValue ? dayjs(stringValue).format('LT') : '';

export const localDateTime = (stringValue, usr) => {
  if (usr?.locale !== 'default')
    return stringValue ? dayjs(stringValue).format('L LT') : '';
  return stringValue
    ? `${dayjs(stringValue).toISOString().slice(0, 10)} ${dayjs(
        stringValue
      ).format('LT')}`
    : '';
};
export const getRoles = (user) => {
  const roles = [];
  if (user.auditRole.expert) {
    roles.push(AUDIT_ROLE.EXPERT);
  }
  if (user.auditRole.interpreter) {
    roles.push(AUDIT_ROLE.INTERPRETER);
  }
  if (user.auditRole.participant) {
    roles.push(AUDIT_ROLE.PARTICIPANT);
  }
  if (user.auditRole.support) {
    roles.push(AUDIT_ROLE.SUPPORT);
  }
  if (user.auditRole.teamLeader) {
    roles.push(AUDIT_ROLE.TEAM_LEADER);
  }
  if (user.auditRole.teamLeaderDeputy) {
    roles.push(AUDIT_ROLE.TEAM_LEADER_DEPUTY);
  }
  if (user.auditRole.witness) {
    roles.push(AUDIT_ROLE.WITNESS);
  }
  if (user.auditRole.trainee) {
    roles.push(AUDIT_ROLE.TRAINEE);
  }
  if (user.auditRole.mainContactPerson) {
    roles.push(AUDIT_ROLE.MAIN_CONTACT_PERSON);
  }

  return roles;
};

export const userRecordToInvitee = (user, roles) => ({
  id: user.id,
  userID: user.hr_number,
  email: user.email,
  name: user.name,
  profile_picture: user.profile_picture,
  initials: user.initials,
  customerNumber: user.customer_number,
  auditRole: {
    support: (roles || []).includes(AUDIT_ROLE.SUPPORT),
    participant: (roles || []).includes(AUDIT_ROLE.PARTICIPANT),
    expert: (roles || []).includes(AUDIT_ROLE.EXPERT),
    witness: (roles || []).includes(AUDIT_ROLE.WITNESS),
    interpreter: (roles || []).includes(AUDIT_ROLE.INTERPRETER),
    teamLeader: (roles || []).includes(AUDIT_ROLE.TEAM_LEADER),
    teamLeaderDeputy: (roles || []).includes(AUDIT_ROLE.TEAM_LEADER_DEPUTY),
    trainee: (roles || []).includes(AUDIT_ROLE.TRAINEE),
    mainContactPerson: (roles || []).includes(AUDIT_ROLE.MAIN_CONTACT_PERSON),
  },
});

/* Generic function which looks for a property value by key in an array. In case it is found is returned,
otherwise returns undefined
 */
export const getIfObjectIncluded = (arr, keyToCompare, valueToCheck) =>
  arr.find((el) => el[keyToCompare] === valueToCheck);

export const storeError = (error) => {
  try {
    localStorage.setItem(
      `${nanoid(10)}-${new Date().toISOString()}`,
      JSON.stringify(error)
    );
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {*} value is the value, which should be mapped to the translation key
 * @param {number} type is the value, which kind of translation or which area in the app needs to be translated
 * @returns {(string|null)}
 */
export const getTranslationKeyByValueAndType = (value, type) => {
  switch (type) {
    case TRANSLATION_CATEGORY.AUDIT_TYPE: {
      const auditTypeRes = AUDIT_TYPES.find(
        (auditType) => auditType.value === value
      );

      if (typeof auditTypeRes !== 'undefined') {
        return auditTypeRes.label;
      }
      break;
    }
    case TRANSLATION_CATEGORY.PROFILE:
      break;
    case TRANSLATION_CATEGORY.WELCOME_PAGE_CATEGORY:
      break;
    default:
      break;
  }
  return null;
};

export function ValidationResult() {
  this.result = {};
  this.errors = {};
}

ValidationResult.prototype.logResult = function (propName, result) {
  console.log(`[VALIDATION-RESULT]:: Propname ${propName}: ${result}`);
};
ValidationResult.prototype.logError = function (propName, result) {
  console.log(`[VALIDATION-RESULT]:: Propname ${propName}: ${result}`);
};
ValidationResult.prototype.addvalidationError = function (propName, error) {
  this.errors[propName] = error;
  this.logError(propName, error);
};
ValidationResult.prototype.addvalidationResult = function (propName, result) {
  this.result[propName] = result;
  this.logResult(propName, result);
};
ValidationResult.prototype.isValid = function () {
  return Object.keys(this.result).every((resultKey) => this.result[resultKey]);
};
ValidationResult.prototype.hasErrors = function () {
  return Object.keys(this.errors).length >= 1;
};
