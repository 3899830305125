import { takeEvery, call, put, select } from 'redux-saga/effects';
import set from 'lodash.set';
import { getUsersListPageSuccess } from '../actions';
import { fetchUsersPage } from '../api/api';
import {
  GET_USERS_LIST_PAGE,
  SET_USERS_LIST_PAGE,
  SET_USERS_LIST_QUERY,
  SET_USERS_LIST_SORT,
  SET_USERS_LIST_STATUS,
} from '../constants';

const querySelector = (state) => state.usersList.query;
const sourceSelector = (state) => state.usersList.source;
const pageSelector = (state) => state.usersList.page;
const sortDirSelector = (state) => state.usersList.sortDir;
const sortPropSelector = (state) => state.usersList.sortProp;
const adminSelector = (state) => state.usersList.admin;
const roleSelector = (state) => state.usersList.role;

const getOrderBy = (sortProp, sortDir) =>
  set({}, sortProp, sortDir === 1 ? 'asc' : 'desc');

export function* handleUsersListPageSaga() {
  try {
    const query = yield select(querySelector);
    const source = yield select(sourceSelector);
    const page = yield select(pageSelector);
    const sortDir = yield select(sortDirSelector);
    const sortProp = yield select(sortPropSelector);
    const admin = yield select(adminSelector);
    const role = yield select(roleSelector);
    const offset = (page - 1) * 10;
    const response = yield call(
      fetchUsersPage,
      query,
      source,
      offset,
      sortProp ? getOrderBy(sortProp, sortDir) : undefined,
      admin,
      role
    );
    yield put(
      getUsersListPageSuccess(
        response.data.ras_users,
        response.data.ras_users_aggregate.aggregate.count
      )
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call(console.error, error);
  }
}

export function* usersListPageSaga() {
  yield takeEvery(
    [
      GET_USERS_LIST_PAGE,
      SET_USERS_LIST_QUERY,
      SET_USERS_LIST_STATUS,
      SET_USERS_LIST_PAGE,
      SET_USERS_LIST_SORT,
    ],
    handleUsersListPageSaga
  );
}
