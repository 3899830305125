export const GET_AUDIT_LIST_PAGE = 'GET_AUDIT_LIST_PAGE';
export const TOGGLE_AUDIT_LIST_ADVANCED_FILTER =
  'TOGGLE_AUDIT_LIST_ADVANCED_FILTER';
export const RESET_ADVANCED_FILTER = 'RESET_ADVANCED_FILTER';
export const GET_AUDIT_LIST_PAGE_SUCCESS = 'GET_AUDIT_LIST_PAGE_SUCCESS';
export const GET_AUDIT_LIST_PAGE_ERROR = 'GET_AUDIT_LIST_PAGE_ERROR';
export const SET_AUDIT_LIST_QUERY = 'SET_AUDIT_LIST_QUERY';
export const SET_AUDIT_LIST_STATUS = 'SET_AUDIT_LIST_STATUS';
export const SET_ADVANCED_FILTER = 'SET_ADVANCED_FILTER';
export const SET_AUDIT_LIST_PAGE = 'SET_AUDIT_LIST_PAGE';
export const SET_AUDIT_LIST_EXPANSION_STATE = 'SET_AUDIT_LIST_EXPANSION_STATE';
export const SET_AUDIT_LIST_SORT = 'SET_AUDIT_LIST_SORT';

export const GET_USERS_LIST_PAGE = 'GET_USERS_LIST_PAGE';
export const GET_USERS_LIST_PAGE_SUCCESS = 'GET_USERS_LIST_PAGE_SUCCESS';
export const GET_USERS_LIST_PAGE_ERROR = 'GET_USERS_LIST_PAGE_ERROR';
export const SET_USERS_LIST_QUERY = 'SET_USERS_LIST_QUERY';
export const SET_USERS_LIST_STATUS = 'SET_USERS_LIST_STATUS';
export const SET_USERS_LIST_PAGE = 'SET_USERS_LIST_PAGE';
export const SET_USERS_LIST_SORT = 'SET_USERS_LIST_SORT';

export const GET_DASHBOARD_AUDIT_LIST_PAGE = 'GET_DASHBOARD_AUDIT_LIST_PAGE';
export const GET_DASHBOARD_AUDIT_LIST_PAGE_SUCCESS =
  'GET_DASHBOARD_AUDIT_LIST_PAGE_SUCCESS';
export const GET_DASHBOARD_AUDIT_LIST_PAGE_ERROR =
  'GET_DASHBOARD_AUDIT_LIST_PAGE_ERROR';
export const SET_DASHBOARD_AUDIT_LIST_QUERY = 'SET_DASHBOARD_AUDIT_LIST_QUERY';
export const SET_DASHBOARD_AUDIT_LIST_STATUS =
  'SET_DASHBOARD_AUDIT_LIST_STATUS';
export const SET_DASHBOARD_AUDIT_LIST_PAGE = 'SET_DASHBOARD_AUDIT_LIST_PAGE';
export const SET_DASHBOARD_AUDIT_LIST_SORT = 'SET_DASHBOARD_AUDIT_LIST_SORT';
export const SET_DASHBOARD_AUDIT_LIST_EXPANSION_STATE =
  'SET_DASHBOARD_AUDIT_LIST_EXPANSION_STATE';
export const SET_DASHBOARD_AUDIT_LIST_USER_ID =
  'SET_DASHBOARD_AUDIT_LIST_USER_ID';
