import React from 'react';
import PropTypes from 'prop-types';
import { storeError } from '../../common/utils';
import ErrorPage from './ErrorPage';
import { ERROR } from '../../common/constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
      errorObject: null,
      errorType: ERROR.IS_INTERNAL_ERROR,
    };
  }

  static getDerivedStateFromError(error) {
    try {
      const errorType = error?.type || ERROR.IS_INTERNAL_ERROR;
      const errorMessage = error?.message || error;
      storeError(error);
      return {
        hasError: true,
        errorMessage,
        errorObject: error,
        errorType,
      };
    } catch (err) {
      console.log(err);
      return {
        hasError: true,
        errorMessage: error.toString(),
        errorObject: error,
        errorType: ERROR.IS_INTERNAL_ERROR,
      };
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    const { hasError, errorMessage, errorType, errorObject } = this.state;

    return hasError ? (
      <ErrorPage
        additionalInformation={errorMessage}
        exception={errorObject}
        errorType={errorType}
      />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {};
