import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import autobahn from 'autobahn';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { UserContext } from './UserContextProvider';
import { AppContext } from '../AppContextProvider';
import { getToken } from '../common/auth-utils';
import { NotificationContext } from './NotificationsContext';
import { ADD_WAMP_LOG } from '../components/audit-session/gql-model';
import { AgendaContext } from './AgendaContext';

export const RealTimeContext = React.createContext();

const RealTimeContextProvider = ({ children, sessionId, auditId }) => {
  const { user } = useContext(UserContext);
  const { notify } = useContext(NotificationContext);
  const { setCurrentUserOnline } = useContext(AgendaContext);
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const account = accounts.length > 0 ? accounts[0] : undefined;
  const { config } = useContext(AppContext);
  const [usersOnline, setUsersOnline] = useState([]);
  const [connection, setConnection] = useState();
  const [realtimeSession, setRealtimeSession] = useState(null);
  const connected = useRef();
  const onlineUsersRef = useRef([]);
  const autobahnConnection = useRef();
  let subscriptions = [];
  const client = useApolloClient();

  const onWAMPChallenge = async (withSession = true) => {
    const token = await getToken(instance, config, account);
    return JSON.stringify({
      token: token.accessToken,
      sessionId: withSession ? sessionId : '',
    });
  };
  const connect = async () => {
    const onAnyError = (error, customErrorMessage, type) => {
      notify(t('MESSAGES.REALTIMECONTEXT.ONCLIENTERROR'), 'error');
      setRealtimeSession(null);
    };
    const onUserError = (error, customErrorMessage) => {
      onAnyError(error, customErrorMessage, 'onUserError');
    };
    const onInternalError = (error, customErrorMessage) => {
      onAnyError(error, customErrorMessage, 'onInternalError');
    };
    const onChallenge = async () => {
      const authPayload = await onWAMPChallenge();
      return authPayload;
    };
    autobahnConnection.current = new autobahn.Connection({
      url: config.wsEndpoint,
      realm: 'ras',
      max_retries: -1,
      initial_retry_delay: 1.5,
      retry_delay_growth: 2,
      max_retry_delay: 20,
      authmethods: ['ticket'],
      authid: user.email,
      onchallenge: onChallenge,
      on_user_error: onUserError,
      on_internal_error: onInternalError,
    });

    autobahnConnection.current.onopen = async (session) => {
      console.log('rtctx session onopen', session);
      setRealtimeSession(session);
      session
        .subscribe('wamp.session.on_join', (sessions) => {
          const newValue = [
            ...onlineUsersRef.current,
            ...sessions
              .map((sessInfo) => ({
                sessionId: sessInfo.session,
                userId: sessInfo.authid,
                userAgent: sessInfo.transport,
                auditSessionId: sessInfo.authextra?.auditSessionId,
              }))
              .filter(
                (sess) =>
                  !onlineUsersRef.current.some(
                    (onlineUser) => onlineUser.sessionId === sess.sessionId
                  ) && sess.auditSessionId === sessionId
              ),
          ];

          onlineUsersRef.current = newValue;
          setUsersOnline(onlineUsersRef.current);
        })
        .then((subscription) => subscriptions.push(subscription));
      session
        .subscribe('wamp.session.on_leave', (sessionIds) => {
          const newValue = onlineUsersRef.current.filter(
            (onlineUser) => !sessionIds.includes(onlineUser.sessionId)
          );
          onlineUsersRef.current = newValue;
          setUsersOnline(onlineUsersRef.current);
        })
        .then((subscription) => subscriptions.push(subscription));
      // todo: replace by session.call("wamp.subscription.lookup", ["com.myapp.topic1"]).then(session.log, session.log)
      // todo: subscribe to audit session topic
      const existingSessions = await session.call('wamp.session.list');
      const sessionsInfos = await Promise.all(
        existingSessions.map((existingSession) =>
          session.call('wamp.session.get', [existingSession])
        )
      );

      const newOnlineUsers = [
        ...onlineUsersRef.current,
        ...sessionsInfos
          .map((sessInfo) => {
            let mobile = false;
            if (
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                sessInfo.transport?.http_headers_received?.['user-agent']
              )
            ) {
              mobile = true;
            }
            return {
              auditSessionId: sessInfo.authextra?.auditSessionId,
              sessionId: sessInfo.session,
              userId: sessInfo.authid,
              mobile,
            };
          })
          .filter(
            (sess) =>
              !onlineUsersRef.current.some(
                (onlineUser) => onlineUser.sessionId === sess.sessionId
              ) && sessionId === sess.auditSessionId
          ),
      ];
      onlineUsersRef.current = newOnlineUsers;
      setUsersOnline(onlineUsersRef.current);
    };

    autobahnConnection.current.onclose = (reason, details) => {
      console.log('close rctx', reason, details);
      setRealtimeSession(null);
      // todo: log that issue
      try {
        client.mutate({
          mutation: ADD_WAMP_LOG,
          variables: {
            auditId,
            userId: user.id,
            log: { reason, details },
          },
        });
        subscriptions.forEach((sub) => {
          try {
            console.log('unsubscribe from subscription ', sub);
            autobahnConnection.current?.session?.unsubscribe(sub);
          } catch (err) {
            console.log(err, 'unsubscribe from subscription failed - rtctx');
          }
        });
        subscriptions = [];
      } catch (e) {
        console.log('rtctx wamp logging & unsubscribing');
      }
      setCurrentUserOnline(false);
    };
    autobahnConnection.current?.open();
  };

  useEffect(() => {
    if (user && !connected.current) {
      connected.current = true;
      connect();
    }
    return () => {
      console.log('cleanup subscriptions - rtctx');
      if (subscriptions.length >= 1) {
        subscriptions.forEach((sub) => {
          if (sub) {
            try {
              console.log('unsubscribe from subscription ', sub);
              autobahnConnection.current?.session?.unsubscribe(sub);
            } catch (err) {
              console.log(err, 'unsubscribe from subscription failed - rtctx');
            }
          }
        });
      }

      if (
        autobahnConnection.current?.isConnected &&
        autobahnConnection.current?.isOpen
      ) {
        console.log(autobahnConnection.current);
        autobahnConnection.current?.session?.onleave('RTCTX', 'Ciao bella');
      }
    };
  }, [config.wsEndpoint, setUsersOnline, user]);

  const userIsOnline = (userId) =>
    onlineUsersRef.current.some((onlineUser) => onlineUser.userId === userId);

  const userIsOnPhone = (userId) =>
    onlineUsersRef.current.some(
      (onlineUser) => onlineUser.userId === userId && onlineUser.mobile
    );

  return (
    <RealTimeContext.Provider
      value={{
        user,
        onlineUsers: usersOnline,
        userIsOnline,
        userIsOnPhone,
        connection,
        realtimeSession,
        onWAMPChallenge,
        sessionId,
        subscriptions,
      }}
    >
      {children}
    </RealTimeContext.Provider>
  );
};

RealTimeContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sessionId: PropTypes.string,
  auditId: PropTypes.number,
};

export default RealTimeContextProvider;
