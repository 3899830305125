import { combineReducers } from 'redux';
import auditListReducer from './reducers/auditListReducer';
import configReducer from './reducers/configReducer';
import usersListReducer from './reducers/usersListReducer';
import dashboardAuditsListReducer from './reducers/dashboardAuditsListReducer';

const rootReducer = combineReducers({
  auditList: auditListReducer,
  usersList: usersListReducer,
  dashboardAuditsList: dashboardAuditsListReducer,
  config: configReducer,
});

export default rootReducer;
