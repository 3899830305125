import { useContext, useEffect, useState } from 'react';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AppShell } from './pages/AppShell';
import { AppContext } from './AppContextProvider';
import ErrorBoundary from './components/common/ErrorBoundary';
import MSALErrorPage from './components/common/MSALErrorPage';
import MSALLoading from './components/common/MSALLoading';

const AuthorizedContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const { config } = useContext(AppContext);
  const account = useAccount(accounts[0] || {});
  const [atsResponse, setAtsResponse] = useState(null);

  useEffect(() => {
    if (!account && inProgress === 'none') {
      instance.loginRedirect({
        scopes: config.idp.scopes,
      });
      return;
    }
    if (account && inProgress === 'none') {
      instance
        .acquireTokenSilent({
          scopes: config.idp.scopes,
          account,
        })
        .then((response) => {
          setAtsResponse(response);
        })
        .catch((err) => {
          if (err.errorMessage && err.errorMessage.includes('AADB2C90077')) {
            return instance.acquireTokenRedirect({
              scopes: config.idp.scopes,
              account,
              state: window.location.pathname,
            });
          }
        });
    }
  }, [account, inProgress, instance, config.idp.scopes]);

  return <div className="h-full">{atsResponse ? <Protected /> : null}</div>;
};

export function Protected() {
  const { config } = useContext(AppContext);
  const authRequest = {
    scopes: config.idp.scopes,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={MSALErrorPage}
      loadingComponent={MSALLoading}
    >
      <ErrorBoundary>
        <AppShell />
      </ErrorBoundary>
    </MsalAuthenticationTemplate>
  );
}

export { AuthorizedContent };
