import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTachometerAlt,
  faTasks,
  faLandmark,
  faUsers,
  faEye,
  faLocationArrow,
  faVideo,
  faMicrophoneSlash,
  faVideoSlash,
  faVolumeUp,
  faVolumeSlash,
  faHeadset,
  faCaretLeft,
  faCaretRight,
  faDesktopAlt,
  faSignalStream,
  faMicrophone,
  faSearch,
  faTrash,
  faLock,
  faPen,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';

import { faCog, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

library.add(
  faTachometerAlt,
  faTasks,
  faLandmark,
  faUsers,
  faEye,
  faLocationArrow,
  faVideo,
  faMicrophoneSlash,
  faVideoSlash,
  faVolumeUp,
  faVolumeSlash,
  faHeadset,
  faCaretLeft,
  faCaretRight,
  faDesktopAlt,
  faSignalStream,
  faMicrophone,
  faCog,
  faMinusCircle,
  faSearch,
  faLock,
  faPen,
  faInfoCircle
);

// TODO: only temporary, should use library instead
export {
  faTachometerAlt,
  faTasks,
  faLandmark,
  faUsers,
  faEye,
  faLocationArrow,
  faVideo,
  faMicrophoneSlash,
  faVideoSlash,
  faVolumeUp,
  faVolumeSlash,
  faHeadset,
  faCaretLeft,
  faCaretRight,
  faDesktopAlt,
  faSignalStream,
  faMicrophone,
  faCog,
  faMinusCircle,
  faSearch,
  faTrash,
  faLock,
  faPen,
  faInfoCircle,
};
