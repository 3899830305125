import React from 'react';
import PropTypes from 'prop-types';

export const AppContext = React.createContext();

const AppContextProvider = ({ children, config }) => (
  <AppContext.Provider value={{ config }}>{children}</AppContext.Provider>
);

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  config: PropTypes.object,
};

export default AppContextProvider;
