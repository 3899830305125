import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

export const ShowLongTextContext = React.createContext();

const ShowLongTextContextProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  /**
   *
   * @param {*} text
   * @param {('error'|'success'|'warning'|'info')} type
   * @param {*} stick
   */
  const showLongText = (text, type, sticks = true) => {
    setNotification({ text, type, id: nanoid(), stick: sticks });
  };

  const dismiss = () => {
    setNotification(null);
  };

  return (
    <ShowLongTextContext.Provider
      value={{ showLongText, dismiss, notification }}
    >
      {children}
    </ShowLongTextContext.Provider>
  );
};

ShowLongTextContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ShowLongTextContextProvider;
