import { useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

const baseUrl = window.location.origin; // protocol, hostname and port number
let url = `${baseUrl}/svc/translate`; // route /svc/translate defined in ingress

if (process.env?.NODE_ENV.toLowerCase() === 'development') {
  url = 'http://localhost:3444';
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV &&
      process.env.NODE_ENV.toLowerCase() === 'development',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
        HttpApi, // primary
        resourcesToBackend(() => import(`./en.json`)), // fallback
      ],
      backendOptions: [
        {
          loadPath: `${url}/getTranslationByCode/{{lng}}`, // http api load path for my own fallback
        },
      ],
    },
  });

export default i18n;
