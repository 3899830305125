import { spawn } from 'redux-saga/effects';
import { auditListPageSaga } from './sagas/auditListPageSaga';
import { usersListPageSaga } from './sagas/usersListPageSagas';
import { dashboardAuditsListPageSaga } from './sagas/dashboardAuditsListPageSaga';

export default function* rootSaga() {
  yield spawn(auditListPageSaga);
  yield spawn(usersListPageSaga);
  yield spawn(dashboardAuditsListPageSaga);
}
