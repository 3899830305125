import { createStore, applyMiddleware, compose } from 'redux';

import createSagaMiddleware from 'redux-saga';
import reduxWebsocket from '@giantmachines/redux-websocket';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export default function configureStore(initialState = {}) {
  const reduxSagaMonitorOptions = {};

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const reduxWebsocketMiddleware = reduxWebsocket({
    reconnectOnClose: true,
  });

  const middleware = applyMiddleware(sagaMiddleware, reduxWebsocketMiddleware);
  const store = createStore(rootReducer, initialState, compose(middleware));

  sagaMiddleware.run(rootSaga);

  return { store };
}
