import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

export const NotificationContext = React.createContext();

const NotificationContextProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  /**
   *
   * @param {*} text
   * @param {('error'|'success'|'warning'|'info')} type
   * @param {*} stick
   * @param {*} duration
   */
  const notify = (text, type, stick = false, duration = 5000) => {
    let stickIt = stick;
    const durationTime = duration;
    if (type === 'error') {
      stickIt = true;
    }
    setNotification({
      text,
      type,
      id: nanoid(),
      stick: stickIt,
      duration: durationTime,
    });
  };

  const dismiss = () => {
    setNotification(null);
  };

  return (
    <NotificationContext.Provider value={{ notify, dismiss, notification }}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default NotificationContextProvider;
