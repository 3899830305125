import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const AgendaContext = createContext(null);

const AgendaContextProvider = ({ children }) => {
  const [agendaLanes, setAgendaLanes] = useState([]);
  const prevMeetings = useRef(null);
  const [currentUserOnline, setCurrentUserOnline] = useState(false);
  return (
    <AgendaContext.Provider
      value={{
        agendaLanes,
        setAgendaLanes,
        prevMeetings,
        currentUserOnline,
        setCurrentUserOnline,
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

AgendaContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AgendaContextProvider;
